import React, {useState} from 'react'
import {Link} from 'react-router-dom';

import Navbar from '../../component/Navbar/navbar'
import Footer from '../../component/Footer/footer';

import {
    MdKeyboardArrowRight,
    FaArrowRight,
    BsCheckCircle,
    FaArrowDown,
    FaHeart,
    FaUserPlus, FaEye
} from "../../assets/icons/icons"
import { pricingTableData} from '../../data/dataTwo';
import shree_logo from "../../assets/images/igchamp/select-package.png";
import google_logo from "../../assets/images/igchamp/put-info.png";
import lenovo_logo from "../../assets/images/igchamp/payment.png";
import circle_logo from "../../assets/images/igchamp/deliver-order.png";
import classic02 from "../../assets/images/igchamp/buy-tiktok-likes.png";
import {Helmet} from "react-helmet";

export default function TikTokLikes() {
    const [activeIndex, setActiveIndex] = useState(0);
    const [pricingTable, setpricingTable] = useState(pricingTableData.filter((element) => {
        return element.cat === 21;
    }))
    const toggleAccordion = (index) => {
        if (activeIndex === index) {
            setActiveIndex(0);
        } else {
            setActiveIndex(index);
        }
    }
    const ldJson=
        {
            "@context": "https://schema.org/",
            "@type": "BreadcrumbList",
            "itemListElement": [
                {
                    "@type": "ListItem",
                    "position": 1,
                    "name": "Home",
                    "item": "https://igchamp.com"
                },
                {
                    "@type": "ListItem",
                    "position": 2,
                    "name": "Buy TikTok Likes",
                    "item": "https://igchamp.com/buy-tiktok-likes"
                }
            ]
        };
    return (
        <>
            <Helmet>
                <title>Buy TikTok Likes - 100% Real and Instant - Starting from $1</title>
                <meta name="description"
                      content="Increase your profile presence by buying real TikTok likes with IG Champ. Buy our reliable services today to let your account make a difference."/>
                <meta property="og:title" content="Buy TikTok Likes - 100% Real and Instant - Starting from $1"/>
                <meta property="og:description"
                      content="Increase your profile presence by buying real TikTok likes with IG Champ. Buy our reliable services today to let your account make a difference."/>
                <meta name="keywords" content="Buy Tiktok Likes, real and Instant Likes"/>
                <meta property="og:image" content={classic02}/>
                <meta property="og:url" content="/buy-tiktok-lieks"/>
                <link rel="canonical" href="https://igchamp.com/buy-tiktok-likes"/>
                <script type="application/ld+json">
                    {JSON.stringify(ldJson)}
                </script>
            </Helmet>
            <Navbar/>

            <section className="relative table w-full py-32 lg:py-40 bg-gray-50 dark:bg-slate-800">
                <div className="container relative">
                    <div className="grid grid-cols-1 text-center mt-10">
                        <h1 className="text-3xl leading-normal font-semibold">Buy TikTok Likes and Get Noticed</h1>
                        <p>Buy TikTok likes and get noticed instantly with IGChamp. More likes mean increased visibility
                            for your content. This translates to a wider audience and more engagement. Boost your TikTok
                            presence effortlessly and watch your follower count grow. </p>
                    </div>
                </div>
                <div className="absolute text-center z-10 bottom-5 start-0 end-0 mx-3">
                    <ul className="tracking-[0.5px] mb-0 inline-flex space-x-1">
                        <li className="inline-block uppercase text-[13px] font-bold duration-500 ease-in-out hover:text-indigo-600">
                            <Link to="/">Home</Link></li>
                        <li className="inline-block text-base mx-0.5 ltr:rotate-0 rtl:rotate-180"><MdKeyboardArrowRight
                            className="text-xl"/></li>
                        <li className="inline-block uppercase text-[13px] font-bold duration-500 ease-in-out hover:text-indigo-600">
                            <Link>TikTok</Link></li>
                        <li className="inline-block text-base mx-0.5 ltr:rotate-0 rtl:rotate-180"><MdKeyboardArrowRight
                            className="text-xl"/></li>
                        <li className="inline-block uppercase text-[13px] font-bold duration-500 ease-in-out text-indigo-600"
                            aria-current="page">Buy TikTok Likes
                        </li>
                    </ul>
                </div>
            </section>

            <div className="relative">
                <div
                    className="shape absolute sm:-bottom-px -bottom-[2px] start-0 end-0 overflow-hidden z-1 text-white dark:text-slate-900">
                    <svg className="w-full h-auto scale-[2.0] origin-top" viewBox="0 0 2880 48" fill="none"
                         xmlns="http://www.w3.org/2000/svg">
                        <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
                    </svg>
                </div>
            </div>

            <section className="relative md:py-24 py-16">
                <div className="container relative">
                    <div className="grid grid-cols-1 pb-8 text-center">
                        <h3 className="mb-4 md:text-3xl md:leading-normal text-2xl leading-normal font-semibold">Choose
                            Pricing
                            Plan</h3>

                        <p className="text-slate-400 max-w-xl mx-auto">Your account should be public!</p>
                    </div>
                    <div id="StarterContent" className="mt-6">
                        <div className="grid lg:grid-cols-4 md:grid-cols-2 mt-8 gap-[30px]">
                            {pricingTable.map((pricing, index) => {
                                return (
                                    <div key={index}
                                         className="group border-b-[3px] dark:border-gray-700 p-6 py-8 hover:border-indigo-600 dark:hover:border-indigo-600 transition-all duration-500 ease-in-out hover:scale-105 relative overflow-hidden shadow dark:shadow-gray-800 rounded-md bg-gray-50 dark:bg-slate-800 hover:bg-white dark:hover:bg-slate-900 h-fit">
                                        <h6 className="font-bold uppercase mb-5 text-indigo-600">{pricing.name}</h6>

                                        <div className="flex mb-5">
                                            <span className="text-xl font-semibold">$</span>
                                            <span className="price text-4xl font-semibold mb-0">{pricing.price}</span>
                                            <span className="text-xl font-semibold self-end mb-1"></span>
                                        </div>

                                        <ul className="list-none text-slate-400">
                                            <li className="mb-1 flex items-center" key={"a" + pricing.id}>
                                                <BsCheckCircle className="text-indigo-600 text-base me-2"/> 5 Minutes to
                                                24Hours
                                            </li>
                                            <li className="mb-1 flex items-center" key={"b" + pricing.id}>
                                                <BsCheckCircle className="text-indigo-600 text-base me-2"/> Safe &
                                                Secure
                                            </li>
                                            <li className="mb-1 flex items-center" key={"c" + pricing.id}>
                                                <BsCheckCircle className="text-indigo-600 text-base me-2"/> Real and
                                                Active
                                            </li>
                                            <li className="mb-1 flex items-center" key={"d" + pricing.id}>
                                                <BsCheckCircle className="text-indigo-600 text-base me-2"/> Money-Back
                                                Guarantee
                                            </li>
                                        </ul>
                                        <a
                                            className="py-2 px-5 inline-block font-semibold tracking-wide border align-middle duration-500 text-base text-center bg-indigo-600 hover:bg-indigo-700 border-indigo-600 hover:border-indigo-700 text-white rounded-md mt-5"
                                            href={pricing.link} rel="nofollow">Buy
                                            Now</a>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </div>
            </section>
            <section className="relative md:py-24 py-16 bg-gray-50 dark:bg-slate-800">
                <div className="container relative">
                    <div className="grid grid-cols-1 pb-8 text-center">
                        <h3 className="mb-4 md:text-3xl md:leading-normal text-2xl leading-normal font-semibold">How to
                            Place Order!</h3>
                    </div>

                    <div className="grid grid-cols-1 mt-8">
                        <div
                            className="relative after:content-[''] after:absolute after:top-0 md:after:end-0 md:after:start-0 after:w-px after:h-full md:after:m-auto after:border-s-2 after:border-dashed after:border-gray-200 dark:after:border-gray-700 ms-3 md:ms-0">
                            <div
                                className="ms-8 md:ms-0 relative after:content-[''] after:absolute after:top-[9px] after:rounded-full after:z-10 after:w-2.5 after:h-2.5 after:bg-indigo-600 md:after:mx-auto md:after:end-0 md:after:start-0 after:-start-9 before:content-[''] before:absolute md:before:mx-auto md:before:end-0 md:before:start-0 before:-start-11 before:rounded-full before:z-10 before:border-2 before:border-dashed before:border-gray-200 dark:before:border-gray-700 before:top-0 before:w-7 before:h-7 before:bg-white dark:before:bg-slate-900">
                                <div className="grid md:grid-cols-2">
                                    <div className="md:text-end md:me-8 relative">
                                        <img src={shree_logo} className=" md:ms-auto" alt=""/>
                                    </div>

                                    <div className="ltr:float-left rtl:float-right text-start ms-8 mt-6 md:mt-0">
                                        <h4 className="title mb-1 font-semibold">Select Package</h4>
                                        <p className="mt-3 mb-0 text-slate-400">Selecting your package to buy TikTok
                                            likes with IG Champ is easy and flexible. We offer a variety of packages
                                            tailored to meet different needs and budgets. Whether you’re just starting
                                            or looking to boost an already established account, we have the perfect
                                            option for you. Choose the number of likes that fits your goals and watch
                                            your TikTok presence grow. Our packages are designed to provide maximum
                                            impact, ensuring your content gets the attention it deserves. </p>
                                    </div>
                                </div>
                            </div>

                            <div
                                className="mt-12 ms-8 md:ms-0 relative after:content-[''] after:absolute after:top-[9px] after:rounded-full after:z-10 after:w-2.5 after:h-2.5 after:bg-indigo-600 md:after:mx-auto md:after:end-0 md:after:start-0 after:-start-9 before:content-[''] before:absolute md:before:mx-auto md:before:end-0 md:before:start-0 before:-start-11 before:rounded-full before:z-10 before:border-2 before:border-dashed before:border-gray-200 dark:before:border-gray-700 before:top-0 before:w-7 before:h-7 before:bg-white dark:before:bg-slate-900">
                                <div className="grid md:grid-cols-2">
                                    <div className="text-start ms-8 relative md:order-2">
                                        <img src={google_logo} className=" md:me-auto" alt=""/>
                                    </div>

                                    <div
                                        className="ltr:float-left rtl:float-right md:text-end md:me-8 mt-6 md:mt-0 md:order-1">
                                        <h4 className="title mb-1 font-semibold">Put Desired Info</h4>
                                        <p className="mt-3 mb-0 text-slate-400">Entering your information to buy TikTok
                                            likes with IG Champ is a simple and secure process. Just provide your TikTok
                                            username and select the desired package. No passwords are required, ensuring
                                            your account’s safety and privacy. Our user-friendly interface makes it easy
                                            to complete your purchase in just a few clicks. Once your info is entered,
                                            our team will quickly start delivering the likes to your videos. With IG
                                            Champ, you can trust that your details are handled with care and that your
                                            TikTok growth is in good hands.</p>
                                    </div>
                                </div>
                            </div>

                            <div
                                className="mt-12 ms-8 md:ms-0 relative after:content-[''] after:absolute after:top-[9px] after:rounded-full after:z-10 after:w-2.5 after:h-2.5 after:bg-indigo-600 md:after:mx-auto md:after:end-0 md:after:start-0 after:-start-9 before:content-[''] before:absolute md:before:mx-auto md:before:end-0 md:before:start-0 before:-start-11 before:rounded-full before:z-10 before:border-2 before:border-dashed before:border-gray-200 dark:before:border-gray-700 before:top-0 before:w-7 before:h-7 before:bg-white dark:before:bg-slate-900">
                                <div className="grid md:grid-cols-2">
                                    <div className="md:text-end md:me-8 relative">
                                        <img src={lenovo_logo} className=" md:ms-auto" alt=""/>
                                    </div>

                                    <div className="ltr:float-left rtl:float-right text-start ms-8 mt-6 md:mt-0">
                                        <h4 className="title mb-1 font-semibold">Pay for your order</h4>
                                        <p className="mt-3 mb-0 text-slate-400">Completing your payment to buy TikTok
                                            likes with IG Champ is fast, secure, and hassle-free. We offer multiple
                                            payment options to suit your convenience, ensuring a smooth transaction
                                            process. Once you’ve selected your package and entered your information,
                                            simply proceed to the payment section. Our secure payment gateway protects
                                            your financial details, giving you peace of mind. After completing your
                                            payment, you will receive a confirmation, and our team will promptly start
                                            delivering likes to your TikTok videos. </p>
                                    </div>
                                </div>
                            </div>

                            <div
                                className="mt-12 ms-8 md:ms-0 relative after:content-[''] after:absolute after:top-[9px] after:rounded-full after:z-10 after:w-2.5 after:h-2.5 after:bg-indigo-600 md:after:mx-auto md:after:end-0 md:after:start-0 after:-start-9 before:content-[''] before:absolute md:before:mx-auto md:before:end-0 md:before:start-0 before:-start-11 before:rounded-full before:z-10 before:border-2 before:border-dashed before:border-gray-200 dark:before:border-gray-700 before:top-0 before:w-7 before:h-7 before:bg-white dark:before:bg-slate-900">
                                <div className="grid md:grid-cols-2">
                                    <div className="text-start ms-8 relative md:order-2">
                                        <img src={circle_logo} className=" md:me-auto" alt=""/>
                                    </div>

                                    <div
                                        className="ltr:float-left rtl:float-right md:text-end md:me-8 mt-6 md:mt-0 md:order-1">
                                        <h4 className="title mb-1 font-semibold">Order Delivered</h4>
                                        <p className="mt-3 mb-0 text-slate-400">See the results of your TikTok growth
                                            instantly with IG Champ. After completing your purchase, watch as likes
                                            start pouring into your videos, increasing your visibility and engagement.
                                            Our efficient delivery ensures you see noticeable improvements quickly,
                                            boosting your content’s reach and popularity. Track your progress through
                                            your TikTok analytics and observe the positive impact our services have on
                                            your account. </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="relative overflow-hidden pb-32 bg-gray-50 dark:bg-slate-800">
                <div className="container grid md:grid-cols-2 grid-cols-1 items-center mt-16 gap-[30px]">
                    <div className="relative wow animate__animated animate__fadeInLeft" data-wow-delay=".3s">
                        <img src={classic02} className="rounded-lg dark:shadow-gray-800 mx-auto"
                             alt="Buy TikTok Likes"/>
                        <div
                            className="overflow-hidden absolute lg:h-[400px] h-[320px] lg:w-[400px] w-[320px] bg-indigo-600/5 bottom-0 start-0 rotate-45 -z-1 rounded-3xl"></div>
                    </div>
                    <div className="lg:ms-8 wow animate__animated animate__fadeInRight" data-wow-delay=".3s">
                        <h2 className="mb-4 text-2xl leading-normal font-medium">Buy Likes On TikTok to Make a
                            Difference</h2>
                        <p className="text-slate-400">Buy likes on TikTok to make a difference in your social media
                            presence with IG Champ. Gaining more likes not only enhances the visibility of your videos
                            but also boosts your credibility and influence on the platform. More likes attract more
                            viewers, creating a ripple effect that can significantly grow your audience. By investing in
                            TikTok likes, you ensure your content gets the attention it deserves, setting you apart from
                            the competition. With IG Champ’s reliable and effective services, you can make a tangible
                            difference in your TikTok journey, turning your posts into viral sensations.</p>
                    </div>
                </div>
            </section>
            <section className="relative md:py-24 py-8">
                <div className="container relative">
                    <div className="md:flex justify-center">
                        <div className="">
                            <div className="p-6 bg-white dark:bg-slate-900 shadow dark:shadow-gray-800 rounded-md">
                                <h3 className="text-xl font-semibold mb-4">Buy Tiktok Likes -100% Real and Lightning Fast Delivery</h3>
                                <p className="text-slate-400">You may see thousands of brands popping around the
                                    internet claiming to provide the best TikTok services. However, most of them provide
                                    you followers or like that begin to drop after a certain amount of time. Buy TikTok
                                    likes from <Link to='/'><b className="text-red-600">IGChamp</b></Link> with confidence and we assure you would never regret your choice
                                    as we have mastered the skills to thrive you in this new social media craze –
                                    TikTok.
                                    For people who are new to the app, they may find it intimidating, but don’t let that
                                    switch you off. Many exciting and entertaining things can keep you hooked for the
                                    long haul.
                                </p>
                                <h3 className="text-xl font-semibold mb-4">Why should consider IGChamp to buy TikTok likes</h3>
                                <p className="text-slate-400">Here are Some of the convincing features that Why we necessitate to buy from IGChamp.
                                    Buying TikTok likes from us will give you the most satisfying experience as we have been in this field ever since this social platform launched worldwide.
                                    We have a veteran team and specialized software that can provide you TikTok likes in an automated fashion.
                                    IGChamp believes in achieving a supreme level of customer satisfaction. If the package you chose or services you bought do not satisfy, we give instant money back.
                                    The lightning-fast delivery and 24-hour available support are some of the factors that keep us tall from the competitor crowd.
                                    Reaching millions of people and getting TikTok likes has become the biggest hurdle to cross from noobs to professional Tiktokers.  Like any other social platform, Tiktok has a huge potential to make you a star in no time. If you want to make your dream come true, you should devise a way to get ahead from the competitive crowd,   if you are short of ideas, reach out to us to <Link to='/buy-tiktok-followers'><b className="text-red-600">buy TikTok Followers</b></Link>, <Link to='/buy-tiktok-likes'><b className="text-red-600">Likes</b></Link>, Comments and <Link to='/buy-tiktok-views'><b className="text-red-600">views</b></Link> at the most competitive prices.

                                </p>
                                <h5 className="text-xl font-semibold mb-4 mt-8">Likes In Seconds </h5>
                                <p className="text-slate-400">As soon as you complete your purchase, our system begins
                                    delivering likes to your TikTok videos almost immediately. This rapid boost enhances
                                    your video’s visibility, drawing in more viewers and increasing engagement. With IG
                                    Champ, there’s no waiting around—watch your likes count rise in real time. As a
                                    result, this gives your content the competitive edge it needs. Our swift and
                                    efficient service ensures that your TikTok presence is elevated quickly, helping you
                                    stand out and succeed on the platform.</p>
                                <h5 className="text-xl font-semibold mb-4 mt-8">No Bots</h5>
                                <p className="text-slate-400">At IG Champ, we pride ourselves on providing genuine
                                    engagement with our “No Bots” policy. When you buy likes for TikTok from us, you can
                                    rest assured that every interaction comes from real, active users. Our commitment to
                                    quality means you receive likes from real people who genuinely appreciate your
                                    content, boosting your visibility and organic reach. With IG Champ, you get genuine
                                    engagement that makes a meaningful impact on your TikTok journey.</p>
                                <h5 className="text-xl font-semibold mb-4 mt-8">TikTok Compliant </h5>
                                <p className="text-slate-400">Our services come with a guarantee of being TikTok
                                    compliant. This way, we promise you peace of mind when you buy likes for TikTok. We
                                    adhere strictly to TikTok’s terms of service and <a rel="nofollow" target="_blank" href='https://www.tiktok.com/community-guidelines'><b>community guidelines</b></a>, so all
                                    interactions and engagements are authentic and legitimate. Our approach prioritizes
                                    the integrity of your TikTok account, safeguarding it against any risks or
                                    violations. </p>
                                <h5 className="text-xl font-semibold mb-4 mt-8">Permanent Likes</h5>
                                <p className="text-slate-400">When you boost TikTok likes, you receive permanent likes
                                    that contribute to long-lasting engagement. Unlike temporary boosts or fleeting
                                    interactions, our service ensures that the likes you receive are stable and
                                    enduring. These permanent likes not only enhance your video’s credibility and
                                    visibility but also contribute to sustained growth over time. IG Champ’s commitment
                                    to quality means you can rely on consistent support for your TikTok content. This
                                    will help you build a loyal audience and strengthen your social media presence
                                    effectively. </p>
                                <h5 className="text-xl font-semibold mb-4 mt-8">Affordable Likes</h5>
                                <p className="text-slate-400">At IG Champ, we offer affordable likes tailored to elevate
                                    your TikTok presence without straining your budget. Our cost-effective packages are
                                    designed to provide excellent value, ensuring you get the most out of your
                                    investment. With transparent pricing and no hidden fees, IG Champ makes it easy to
                                    enhance your TikTok videos without compromising on quality. Choose IG Champ for
                                    affordable likes that deliver real results and support your TikTok growth journey
                                    effectively.</p>
                                <h5 className="text-xl font-semibold mb-4 mt-8">100% Confidentiality </h5>
                                <p className="text-slate-400">At IG Champ, we prioritize your privacy with our
                                    commitment to 100% confidentiality. When you buy likes for TikTok from us, your
                                    information and account details are kept strictly confidential. We operate with a
                                    secure and encrypted platform to ensure that your data remains safe at all times.
                                    Our strict privacy policy means that we never share your personal information with
                                    third parties or compromise the security of your TikTok account. You can trust IG
                                    Champ for a discreet and professional service that prioritizes your privacy while
                                    boosting your TikTok presence effectively and safely.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="relative md:py-8 py-16">
                <div className="container relative">
                    <div className="md:flex justify-center">
                        <div className="">
                            <div className="p-6 bg-white dark:bg-slate-900 shadow dark:shadow-gray-800 rounded-md">
                                <div className="md:mt-24 mt-16">
                                    <div className="grid grid-cols-1 pb-8 text-center">
                                        <h3 className="mb-4 md:text-3xl md:leading-normal text-2xl leading-normal font-semibold">Got
                                            Questions? Get the Answers. </h3>
                                    </div>

                                    <div id="accordion-collapse" data-accordion="collapse"
                                         className="grid md:grid-cols-2 grid-cols-1 mt-8 md:gap-[30px]">
                                        <div>
                                            <div key="11"
                                                 className="relative shadow dark:shadow-gray-800 rounded-md overflow-hidden mt-4">
                                                <h4 className="text-base font-semibold"
                                                    id="accordion-collapse-heading-1">
                                                    <button type="button" onClick={() => toggleAccordion(11)}
                                                            className={`flex justify-between items-center p-5 w-full font-medium text-start ${activeIndex === 11 ? 'bg-gray-50 dark:bg-slate-800 text-indigo-600' : ''}`}
                                                            data-accordion-target="#accordion-collapse-body-1"
                                                            aria-expanded="true"
                                                            aria-controls="accordion-collapse-body-1">
                                                        <span>Why should I buy TikTok likes?</span>
                                                        <FaArrowDown
                                                            className={`${activeIndex === 11 ? "rotate-180" : "rotate-270"} w-4 h-4 shrink-01`}></FaArrowDown>
                                                    </button>
                                                </h4>
                                                <div className={activeIndex === 11 ? "" : "hidden"}>
                                                    <div className="p-5">
                                                        <p className="text-slate-400 dark:text-gray-400">Buying TikTok
                                                            likes can significantly enhance your presence on the
                                                            platform by boosting the visibility and credibility of your
                                                            videos. When you purchase likes, you increase the likelihood
                                                            of your content appearing on TikTok’s For You page, which
                                                            exposes it to a larger audience. </p>
                                                        <p className="text-slate-400 dark:text-gray-400">Thus, it can
                                                            lead to higher
                                                            engagement rates, including likes, comments, and shares.
                                                            Additionally, a higher number of likes serves as social
                                                            proof that your content is worth watching.</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div>
                                            <div key="12"
                                                 className="relative shadow dark:shadow-gray-800 rounded-md overflow-hidden mt-4">
                                                <h4 className="text-base font-semibold"
                                                    id="accordion-collapse-heading-1">
                                                    <button type="button" onClick={() => toggleAccordion(12)}
                                                            className={`flex justify-between items-center p-5 w-full font-medium text-start ${activeIndex === 12 ? 'bg-gray-50 dark:bg-slate-800 text-indigo-600' : ''}`}
                                                            data-accordion-target="#accordion-collapse-body-1"
                                                            aria-expanded="true"
                                                            aria-controls="accordion-collapse-body-1">
                                                        <span>Why do I need to buy “real” TikTok likes?</span>
                                                        <FaArrowDown
                                                            className={`${activeIndex === 12 ? "rotate-180" : "rotate-270"} w-4 h-4 shrink-01`}></FaArrowDown>
                                                    </button>
                                                </h4>
                                                <div className={activeIndex === 12 ? "" : "hidden"}>
                                                    <div className="p-5">
                                                        <p className="text-slate-400 dark:text-gray-400">Buying “real”
                                                            TikTok likes ensures authenticity and compliance with
                                                            TikTok’s community guidelines. Real likes come from genuine
                                                            TikTok accounts, which interact with your content naturally
                                                            and contribute to your overall engagement metrics. This can
                                                            positively impact your credibility on TikTok and increase
                                                            the likelihood of your videos being recommended to a wider
                                                            audience. </p>
                                                        <p className="text-slate-400 dark:text-gray-400">Moreover, real
                                                            likes are safer for your TikTok
                                                            account, as they reduce the risk of penalties or
                                                            restrictions that can occur with artificial engagement
                                                            methods.</p>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>

                                        <div>
                                            <div key="13"
                                                 className="relative shadow dark:shadow-gray-800 rounded-md overflow-hidden mt-4">
                                                <h4 className="text-base font-semibold"
                                                    id="accordion-collapse-heading-1">
                                                    <button type="button" onClick={() => toggleAccordion(13)}
                                                            className={`flex justify-between items-center p-5 w-full font-medium text-start ${activeIndex === 13 ? 'bg-gray-50 dark:bg-slate-800 text-indigo-600' : ''}`}
                                                            data-accordion-target="#accordion-collapse-body-1"
                                                            aria-expanded="true"
                                                            aria-controls="accordion-collapse-body-1">
                                                        <span>How long do I have to wait to see the results after making the payment?</span>
                                                        <FaArrowDown
                                                            className={`${activeIndex === 13 ? "rotate-180" : "rotate-270"} w-4 h-4 shrink-01`}></FaArrowDown>
                                                    </button>
                                                </h4>
                                                <div className={activeIndex === 13 ? "" : "hidden"}>
                                                    <div className="p-5">
                                                        <p className="text-slate-400 dark:text-gray-400">The time can
                                                            vary depending on the service provider and the package you
                                                            choose. Generally, some providers begin delivering likes
                                                            almost immediately after payment confirmation, while others
                                                            may have a slight delay depending on their processing times.
                                                        </p>
                                                        <p className="text-slate-400 dark:text-gray-400">With IG Champ,
                                                            you can start noticing an increase in likes
                                                            on your TikTok videos within a few minutes to a few hours
                                                            after completing your purchase. For the most accurate
                                                            estimate, it’s advisable to check the specific delivery
                                                            details provided by the service you choose.
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div>
                                            <div key="14"
                                                 className="relative shadow dark:shadow-gray-800 rounded-md overflow-hidden mt-4">
                                                <h4 className="text-base font-semibold"
                                                    id="accordion-collapse-heading-1">
                                                    <button type="button" onClick={() => toggleAccordion(14)}
                                                            className={`flex justify-between items-center p-5 w-full font-medium text-start ${activeIndex === 14 ? 'bg-gray-50 dark:bg-slate-800 text-indigo-600' : ''}`}
                                                            data-accordion-target="#accordion-collapse-body-1"
                                                            aria-expanded="true"
                                                            aria-controls="accordion-collapse-body-1">
                                                        <span>Do more likes boost my TikTok profile?</span>
                                                        <FaArrowDown
                                                            className={`${activeIndex === 14 ? "rotate-180" : "rotate-270"} w-4 h-4 shrink-01`}></FaArrowDown>
                                                    </button>
                                                </h4>
                                                <div className={activeIndex === 14 ? "" : "hidden"}>
                                                    <div className="p-5">
                                                        <p className="text-slate-400 dark:text-gray-400">On TikTok,
                                                            having more likes on your videos can significantly enhance
                                                            your profile’s visibility and engagement. When your videos
                                                            receive a higher number of likes, TikTok’s algorithm is more
                                                            likely to promote them to a broader audience through
                                                            features like the For You page. This increased visibility
                                                            exposes your content to more viewers and enhances your
                                                            credibility.</p>
                                                        <p className="text-slate-400 dark:text-gray-400"> This is
                                                            because higher like counts serve as
                                                            social proof of your content’s quality and popularity. More
                                                            likes can also contribute to the viral potential of your
                                                            videos, potentially leading to exponential growth in
                                                            followers and engagement. </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div>
                                            <div key="15"
                                                 className="relative shadow dark:shadow-gray-800 rounded-md overflow-hidden mt-4">
                                                <h4 className="text-base font-semibold"
                                                    id="accordion-collapse-heading-1">
                                                    <button type="button" onClick={() => toggleAccordion(15)}
                                                            className={`flex justify-between items-center p-5 w-full font-medium text-start ${activeIndex === 15 ? 'bg-gray-50 dark:bg-slate-800 text-indigo-600' : ''}`}
                                                            data-accordion-target="#accordion-collapse-body-1"
                                                            aria-expanded="true"
                                                            aria-controls="accordion-collapse-body-1">
                                                        <span>Can I buy likes for multiple TikTok videos at once?</span>
                                                        <FaArrowDown
                                                            className={`${activeIndex === 15 ? "rotate-180" : "rotate-270"} w-4 h-4 shrink-01`}></FaArrowDown>
                                                    </button>
                                                </h4>
                                                <div className={activeIndex === 15 ? "" : "hidden"}>
                                                    <div className="p-5">
                                                        <p className="text-slate-400 dark:text-gray-400">Yes, at IG
                                                            Champ, you have the flexibility to buy likes for multiple
                                                            TikTok videos simultaneously. Our platform allows you to
                                                            select and purchase different packages tailored to each
                                                            video’s needs. Whether you’re looking to boost one specific
                                                            video or several at once. </p>
                                                        <p className="text-slate-400 dark:text-gray-400">Our
                                                            straightforward process
                                                            ensures you can efficiently enhance the visibility and
                                                            engagement of your TikTok content. With IG Champ, you can
                                                            easily manage and track the progress of likes across
                                                            multiple videos, maximizing your TikTok growth strategy
                                                            effectively. </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div>
                                            <div key="16"
                                                 className="relative shadow dark:shadow-gray-800 rounded-md overflow-hidden mt-4">
                                                <h4 className="text-base font-semibold"
                                                    id="accordion-collapse-heading-1">
                                                    <button type="button" onClick={() => toggleAccordion(16)}
                                                            className={`flex justify-between items-center p-5 w-full font-medium text-start ${activeIndex === 16 ? 'bg-gray-50 dark:bg-slate-800 text-indigo-600' : ''}`}
                                                            data-accordion-target="#accordion-collapse-body-1"
                                                            aria-expanded="true"
                                                            aria-controls="accordion-collapse-body-1">
                                                        <span>Will buying TikTok likes to help my videos go viral?</span>
                                                        <FaArrowDown
                                                            className={`${activeIndex === 16 ? "rotate-180" : "rotate-270"} w-4 h-4 shrink-01`}></FaArrowDown>
                                                    </button>
                                                </h4>
                                                <div className={activeIndex === 16 ? "" : "hidden"}>
                                                    <div className="p-5">
                                                        <p className="text-slate-400 dark:text-gray-400">Buying TikTok
                                                            likes from IG Champ can certainly increase the likelihood of
                                                            your videos going viral. When you purchase likes, you boost
                                                            your video’s engagement metrics, which can attract TikTok’s
                                                            algorithm to promote your content more widely. </p>
                                                        <p className="text-slate-400 dark:text-gray-400">Higher
                                                            engagement, including likes, comments, and shares, signals
                                                            to TikTok that your video is resonating with viewers. As a
                                                            result, its chances of appearing on the For You page are
                                                            increased. IG Champ’s reliable likes service can
                                                            significantly contribute to amplifying your video’s reach
                                                            and potentially kickstarting viral growth on TikTok.</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div>
                                            <div key="17"
                                                 className="relative shadow dark:shadow-gray-800 rounded-md overflow-hidden mt-4">
                                                <h4 className="text-base font-semibold"
                                                    id="accordion-collapse-heading-1">
                                                    <button type="button" onClick={() => toggleAccordion(17)}
                                                            className={`flex justify-between items-center p-5 w-full font-medium text-start ${activeIndex === 17 ? 'bg-gray-50 dark:bg-slate-800 text-indigo-600' : ''}`}
                                                            data-accordion-target="#accordion-collapse-body-1"
                                                            aria-expanded="true"
                                                            aria-controls="accordion-collapse-body-1">
                                                        <span>How long do the likes I buy for TikTok videos last?</span>
                                                        <FaArrowDown
                                                            className={`${activeIndex === 17 ? "rotate-180" : "rotate-270"} w-4 h-4 shrink-01`}></FaArrowDown>
                                                    </button>
                                                </h4>
                                                <div className={activeIndex === 17 ? "" : "hidden"}>
                                                    <div className="p-5">
                                                        <p className="text-slate-400 dark:text-gray-400">The likes you
                                                            buy for TikTok videos from IG Champ are permanent and do not
                                                            expire. Once delivered to your videos, these likes will
                                                            remain indefinitely part of your video’s engagement metrics.
                                                            Unlike temporary boosts or artificial engagements, the likes
                                                            provided by IG Champ are genuine and contribute to your
                                                            video’s credibility and visibility over the long term.</p>
                                                        <p className="text-slate-400 dark:text-gray-400">This ensures
                                                            that your TikTok content continues to benefit from increased
                                                            social proof and enhanced discoverability. As a result, this
                                                            helps sustain and grow your presence on the platform
                                                            effectively.</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div>
                                            <div key="18"
                                                 className="relative shadow dark:shadow-gray-800 rounded-md overflow-hidden mt-4">
                                                <h4 className="text-base font-semibold"
                                                    id="accordion-collapse-heading-1">
                                                    <button type="button" onClick={() => toggleAccordion(18)}
                                                            className={`flex justify-between items-center p-5 w-full font-medium text-start ${activeIndex === 18 ? 'bg-gray-50 dark:bg-slate-800 text-indigo-600' : ''}`}
                                                            data-accordion-target="#accordion-collapse-body-1"
                                                            aria-expanded="true"
                                                            aria-controls="accordion-collapse-body-1">
                                                        <span>Can buying TikTok likes to increase my follower count?</span>
                                                        <FaArrowDown
                                                            className={`${activeIndex === 18 ? "rotate-180" : "rotate-270"} w-4 h-4 shrink-01`}></FaArrowDown>
                                                    </button>
                                                </h4>
                                                <div className={activeIndex === 18 ? "" : "hidden"}>
                                                    <div className="p-5">
                                                        <p className="text-slate-400 dark:text-gray-400">Buying TikTok
                                                            likes from IG Champ can indirectly contribute to increasing
                                                            your follower count. When your TikTok videos receive more
                                                            likes, they become more visible to a wider audience,
                                                            including potential new followers. Higher engagement
                                                            metrics, such as likes, can make your content appear more
                                                            appealing and credible, prompting viewers to explore your
                                                            profile further and potentially follow you.</p>
                                                        <p className="text-slate-400 dark:text-gray-400">While likes
                                                            themselves do not directly translate to followers, they play
                                                            a crucial role in attracting attention. These also encourage
                                                            viewer engagement, which leads to organic growth in your
                                                            follower base over time. </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div>
                                            <div key="19"
                                                 className="relative shadow dark:shadow-gray-800 rounded-md overflow-hidden mt-4">
                                                <h4 className="text-base font-semibold"
                                                    id="accordion-collapse-heading-1">
                                                    <button type="button" onClick={() => toggleAccordion(19)}
                                                            className={`flex justify-between items-center p-5 w-full font-medium text-start ${activeIndex === 19 ? 'bg-gray-50 dark:bg-slate-800 text-indigo-600' : ''}`}
                                                            data-accordion-target="#accordion-collapse-body-1"
                                                            aria-expanded="true"
                                                            aria-controls="accordion-collapse-body-1">
                                                        <span>How do I choose the right package when buying TikTok likes?</span>
                                                        <FaArrowDown
                                                            className={`${activeIndex === 19 ? "rotate-180" : "rotate-270"} w-4 h-4 shrink-01`}></FaArrowDown>
                                                    </button>
                                                </h4>
                                                <div className={activeIndex === 19 ? "" : "hidden"}>
                                                    <div className="p-5">
                                                        <p className="text-slate-400 dark:text-gray-400">When choosing
                                                            the right package for buying TikTok likes with IG Champ,
                                                            consider several factors to ensure it aligns with your goals
                                                            and budget. </p>
                                                        <p className="text-slate-400 dark:text-gray-400">First, assess
                                                            the number of likes you need for your videos to achieve your
                                                            desired level of visibility and engagement. IG Champ offers
                                                            various packages with different quantities of likes, so
                                                            select one that matches your specific requirements. </p>
                                                        <p className="text-slate-400 dark:text-gray-400">Additionally,
                                                            consider the delivery speed offered by each package—whether
                                                            you prefer gradual delivery or an instant boost. Evaluate
                                                            the pricing and compare it with your budget to ensure
                                                            affordability. </p>
                                                        <p className="text-slate-400 dark:text-gray-400">Lastly, read
                                                            customer reviews and testimonials to understand the
                                                            reliability and effectiveness of IG Champ’s services. </p>
                                                        <p className="text-slate-400 dark:text-gray-400">By carefully
                                                            considering these factors, you can confidently choose the
                                                            package that best suits your TikTok growth strategy with IG
                                                            Champ.</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div>
                                            <div key="20"
                                                 className="relative shadow dark:shadow-gray-800 rounded-md overflow-hidden mt-4">
                                                <h4 className="text-base font-semibold"
                                                    id="accordion-collapse-heading-1">
                                                    <button type="button" onClick={() => toggleAccordion(20)}
                                                            className={`flex justify-between items-center p-5 w-full font-medium text-start ${activeIndex === 20 ? 'bg-gray-50 dark:bg-slate-800 text-indigo-600' : ''}`}
                                                            data-accordion-target="#accordion-collapse-body-1"
                                                            aria-expanded="true"
                                                            aria-controls="accordion-collapse-body-1">
                                                        <span>What should I do if I have issues with the likes I purchased for TikTok?</span>
                                                        <FaArrowDown
                                                            className={`${activeIndex === 20 ? "rotate-180" : "rotate-270"} w-4 h-4 shrink-01`}></FaArrowDown>
                                                    </button>
                                                </h4>
                                                <div className={activeIndex === 20 ? "" : "hidden"}>
                                                    <div className="p-5">
                                                        <p className="text-slate-400 dark:text-gray-400">If you
                                                            encounter any issues with the likes you purchased for your
                                                            TikTok videos from IG Champ, it’s important to promptly
                                                            contact our customer support team for assistance. Provide
                                                            them with specific details, such as your order number and
                                                            TikTok username, along with a clear description of the
                                                            problem. </p>
                                                    </div>
                                                    <div className="p-5">
                                                        <p className="text-slate-400 dark:text-gray-400">IG Champ’s
                                                            support team is dedicated to resolving issues efficiently
                                                            and will guide you through the necessary steps to address
                                                            the issue. Be prepared to follow their instructions and
                                                            provide any additional information they request to
                                                            facilitate a quick resolution. They are committed to
                                                            ensuring your satisfaction with their services and may offer
                                                            replacements or refunds if necessary. Rest assured that IG
                                                            Champ values customer feedback and strives to provide
                                                            reliable support to resolve any issues you may encounter
                                                            with your purchased likes on TikTok.</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="container mb-16">
                <div className="grid grid-cols-1 pb-8 text-center">
                    <h3 className="mb-4 md:text-3xl md:leading-normal text-2xl leading-normal font-semibold">Are you
                        looking for Organic <b className="text-red-600">Tiktok Growth</b>?</h3>


                </div>
                <div className="mt-[30px] lg:mt-0">
                    <div className="grid lg:grid-cols-3 md:grid-cols-3 grid-cols-1 gap-[24px]">
                        <div
                            className="content relative text-center transition-all duration-500 hover:scale-105 shadow dark:shadow-gray-800 hover:shadow-md dark:hover:shadow-gray-700 ease-in-out items-center p-4 rounded-md bg-white dark:bg-slate-900 mt-6">
                            <div className="flex align-middle justify-center items-center mx-auto">
                                <FaHeart className="h-20 w-20 stroke-1 text-indigo-600 text-center"/>
                            </div>
                            <div className="py-1 px-5 inline-flex items-center">
                                <p className="text-slate-400 dark:text-gray-400">Starting from $1.49</p>
                            </div>
                            <div className="content ms-4">
                                <Link to="/buy-tiktok-likes"
                                      className="py-2 px-5 inline-flex items-center font-semibold tracking-wide border align-middle duration-500 text-base text-center bg-indigo-600 hover:bg-indigo-700 border-indigo-600 hover:border-indigo-700 text-white rounded-md me-2 mt-2"><FaHeart
                                    className="me-2 text-sm "/>Buy Tiktok Likes</Link>
                            </div>
                            <div className="py-2 px-5 inline-flex items-center">
                                <ul className="list-none mb-0 text-amber-400 text-xl space-x-1">
                                    <li className="inline"><i className="mdi mdi-star"></i></li>
                                    <li className="inline"><i className="mdi mdi-star"></i></li>
                                    <li className="inline"><i className="mdi mdi-star"></i></li>
                                    <li className="inline"><i className="mdi mdi-star"></i></li>
                                    <li className="inline"><i className="mdi mdi-star"></i></li>
                                </ul>
                            </div>
                            <div className="py-1 px-5 inline-flex items-center">
                                <p className="text-slate-400 dark:text-gray-400">5/5 Rated based on 59 Reviews</p>
                            </div>

                        </div>
                        <div
                            className="content relative text-center transition-all duration-500 hover:scale-105 shadow dark:shadow-gray-800 hover:shadow-md dark:hover:shadow-gray-700 ease-in-out items-center p-4 rounded-md bg-white dark:bg-slate-900 mt-6">
                            <div className="flex align-middle justify-center items-center mx-auto">
                                <FaUserPlus className="h-20 w-20 stroke-1 text-indigo-600 text-center"/>
                            </div>
                            <div className="py-1 px-5 inline-flex items-center">
                                <p className="text-slate-400 dark:text-gray-400">Starting from $1.99</p>
                            </div>
                            <div className="content ms-4">
                                <Link to="/buy-tiktok-followers"
                                      className="py-2 px-5 inline-flex items-center font-semibold tracking-wide border align-middle duration-500 text-base text-center bg-indigo-600 hover:bg-indigo-700 border-indigo-600 hover:border-indigo-700 text-white rounded-md me-2 mt-2"><FaUserPlus
                                    className="me-2 text-sm "/>Buy Tiktok Followers</Link>
                            </div>
                            <div className="py-2 px-5 inline-flex items-center">
                                <ul className="list-none mb-0 text-amber-400 text-xl space-x-1">
                                    <li className="inline"><i className="mdi mdi-star"></i></li>
                                    <li className="inline"><i className="mdi mdi-star"></i></li>
                                    <li className="inline"><i className="mdi mdi-star"></i></li>
                                    <li className="inline"><i className="mdi mdi-star"></i></li>
                                    <li className="inline"><i className="mdi mdi-star"></i></li>
                                </ul>
                            </div>
                            <div className="py-1 px-5 inline-flex items-center">
                                <p className="text-slate-400 dark:text-gray-400">5/5 Rated based on 77 Reviews</p>
                            </div>
                        </div>
                        <div
                            className="content relative text-center transition-all duration-500 hover:scale-105 shadow dark:shadow-gray-800 hover:shadow-md dark:hover:shadow-gray-700 ease-in-out items-center p-4 rounded-md bg-white dark:bg-slate-900 mt-6">
                            <div className="flex align-middle justify-center items-center mx-auto">
                                <FaEye className="h-20 w-20 stroke-1 text-indigo-600 text-center"/>
                            </div>
                            <div className="py-1 px-5 inline-flex items-center">
                                <p className="text-slate-400 dark:text-gray-400">Starting from $2.49</p>
                            </div>
                            <div className="content ms-4">
                                <Link to="/buy-tiktok-views"
                                      className="py-2 px-5 inline-flex items-center font-semibold tracking-wide border align-middle duration-500 text-base text-center bg-indigo-600 hover:bg-indigo-700 border-indigo-600 hover:border-indigo-700 text-white rounded-md me-2 mt-2"><FaEye
                                    className="me-2 text-sm "/> Buy Tiktok Views</Link>
                            </div>
                            <div className="py-2 px-5 inline-flex items-center">
                                <ul className="list-none mb-0 text-amber-400 text-xl space-x-1">
                                    <li className="inline"><i className="mdi mdi-star"></i></li>
                                    <li className="inline"><i className="mdi mdi-star"></i></li>
                                    <li className="inline"><i className="mdi mdi-star"></i></li>
                                    <li className="inline"><i className="mdi mdi-star"></i></li>
                                    <li className="inline"><i className="mdi mdi-star"></i></li>
                                </ul>
                            </div>
                            <div className="py-1 px-5 inline-flex items-center">
                                <p className="text-slate-400 dark:text-gray-400">5/5 Rated based on 87 Reviews</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer/>
        </>
    )
}
