import {
    LiaCrosshairsSolid,
    BiLineChart,
    FiAirplay,
    FiFileText,
    GoRocket,
    GoClock,
    LuUsers,
    LuSearch,
    FaRegQuestionCircle,
    MdOutlineCollectionsBookmark,
    HiOutlineCog6Tooth,
    LiaMoneyCheckAltSolid,
} from '../assets/icons/icons'


import client1 from '../assets/images/client/01.jpg';
import client2 from '../assets/images/client/02.jpg';
import client3 from '../assets/images/client/03.jpg';
import client4 from '../assets/images/client/04.jpg';


import * as Icon from 'react-feather';
import {TbPackage} from "react-icons/tb";
import {BsPencilSquare} from "react-icons/bs";

export const accordionData = [
    {
        id: 1,
        title: 'How can I always get more likes on Instagram?',
        content: 'Consistency is key to increasing likes on Instagram. Post high-quality content regularly to keep your audience engaged. You can also use relevant hashtags to reach a wider audience and encourage interaction by asking questions or running contests. Also, engage with your followers by responding to comments and messages promptly. You shall also collaborate with influencers or participate in Instagram pods to expand your reach. Additionally, consider promoting your posts or using Instagram\'s features like Stories and Reels to attract more attention. By implementing these strategies consistently, you can steadily increase likes on your Instagram posts.'
    },
    {
        id: 2,
        title: 'Why choose IGChamp to buy Instagram likes?',
        content: 'Choosing IGChamp to buy Instagram likes offers several benefits. Firstly, we work with real and engaging profiles to like your posts. This helps maintain credibility and authenticity for your profile. Likewise, our services are also secure and confidential which safeguards your privacy throughout the process. Moreover, we offer a range of economical packages, making it accessible for anyone to enhance their Instagram presence. With IGChamp, you can expect reliable growth and excellent customer service. All of this makes us the ideal choice for boosting your Instagram likes.'
    },
    {
        id: 3,
        title: 'Can Instagram likes help me gain more followers?',
        content: 'Of course! Instagram likes play a crucial role in attracting more followers. When your posts receive a high number of likes, it signals to the Instagram algorithm that your content is engaging and valuable. As a result, your posts are more likely to be shown to a wider audience through the Explore page and hashtags. This results in your account’s increased visibility. Additionally, users are more inclined to follow accounts that already have a significant number of likes, as it indicates credibility and popularity. Therefore, by consistently receiving likes on your posts, you can attract more followers to your Instagram account.'
    },
    {
        id: 4,
        title: 'What kind of content gets more likes on Instagram?',
        content: 'Visually appealing, relevant, and engaging content tends to receive more likes on Instagram. This includes high-quality photos and videos that capture attention, evoke emotions, or tell a compelling story. Additionally, authentic, relatable content that reflects your personality or brand identity resonates well with audiences. Using trending hashtags, posting at optimal times, and engaging with your followers also contribute to increased likes. Experimenting with different content formats, such as carousels, reels, and IGTV, can also help diversify your content and attract more likes. Understanding your audience\'s preferences and interests is key to creating content that consistently receives more likes on Instagram.'
    },
    {
        id: 5,
        title: 'Why should I buy Instagram likes?',
        content: 'While organic growth is valuable, purchasing Instagram likes can strategically boost your visibility and credibility on the platform. Buying likes can help jumpstart your account\'s growth by increasing your posts\' reach and attracting more attention from potential followers. Besides this, it can also give your profile a polished and professional appearance. This will make your profile more appealing to brands and businesses looking for influencers to collaborate with. Furthermore, a higher like count can help you stand out in crowded feeds and attract more organic engagement. Just be sure to complement this strategy with high-quality content and genuine interactions. All of this will help maintain authenticity and build a loyal following over time.'
    },
    {
        id: 6,
        title: 'How do I know how many likes I need per post?',
        content: 'Determining the ideal number of likes per post on Instagram can vary. It depends on several factors, including your goals, target audience, and industry. One approach is to analyze the engagement metrics of your past posts to identify patterns and trends. You shall look for posts that received high levels of engagement in terms of likes, comments, and shares, and replicate those elements in future content. Additionally, consider benchmarking against competitors or similar accounts within your niche. This will help you understand what\'s considered typical or successful in your industry. Ultimately, the right number of likes per post is one that helps you achieve your objectives. Whether it\'s increasing brand awareness, driving website traffic, or generating sales leads. Plus, experimentation is always the key; you can also perform an ongoing analysis to refine your strategy to gradually optimize your Instagram presence.'
    },
    {
        id: 7,
        title: 'What’s the quality of real likes?',
        content: 'Real likes on Instagram come from genuine users who are genuinely interested in your content. These likes reflect authentic engagement. Often these are more valuable than artificially inflated numbers obtained through buying likes or engagement pods. Real likes indicate that your content resonates with your audience, leading to increased visibility and higher credibility. <br>In fact, it also opens more opportunities for growth and collaboration. They also contribute to building a loyal and engaged community around your profile, fostering meaningful connections and interactions. Ultimately, the quality of real likes lies in their authenticity and their ability to drive genuine engagement and support for your content and brand.'

    },
    {
        id: 8,
        title: 'Can I split my bought likes amongst posts?',
        content: 'Some services may offer the option to split purchased likes among multiple posts. However, it’s better to consider the potential consequences and effectiveness of such an approach. Splitting bought likes across posts can dilute the impact and authenticity of engagement on each individual post. This is because the likes won\'t be organically generated by users genuinely interested in each piece of content. Additionally, artificially inflating the like count on multiple posts simultaneously may raise suspicions among your audience. This will result in potential harm your credibility and reputation on the platform.'
    },
    {
        id: 9,
        title: 'What happens when I receive an order ?',
        content: 'There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form.'
    },
    {
        id: 10,
        title: 'Do I need a public Instagram profile for buying likes on Insta?',
        content: 'Yes, you would typically need a public Instagram profile to buy likes on the platform. IGChamp usually requires access to public posts so we can deliver the likes effectively. Additionally, having a public profile ensures that the likes you purchase are visible to all users. This contributes to your overall visibility and credibility on the platform. </br> If your Instagram profile is set to private, it limits the reach and impact of the likes you purchase, as only approved followers can see your posts. Therefore, if you\'re considering buying likes, it\'s advisable to set your profile to public to maximize the benefits of this strategy. However, always ensure to weigh the pros and cons of buying likes and consider the potential impact on your account\'s reputation and authenticity.'
    }
]

export const about = [
    {
        icon: BiLineChart,
        feature: 'Hign Performance'
    },
    {
        icon: LiaCrosshairsSolid,
        feature: 'Best Securities'
    },
    {
        icon: FiAirplay,
        feature: 'Trusted Service'
    },
    {
        icon: GoRocket,
        feature: 'Info Technology'
    },
    {
        icon: GoClock,
        feature: '24/7 Support'
    },
    {
        icon: LuUsers,
        feature: 'IT Management'
    },
    {
        icon: FiFileText,
        feature: 'Certified Company'
    },
    {
        icon: LuSearch,
        feature: 'Data Analytics'
    },
]



export const shopItem = [
    {
        class: '',
        image: client1,
        name: 'Calvin Carlo',
        time: '6th May 2022 at 01:25 pm',
        reply: 'Reply',
        desc: '" There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour "'
    },
    {
        class: 'mt-8',
        image: client2,
        name: 'Calvin Carlo',
        time: '6th May 2022 at 01:25 pm',
        reply: 'Reply',
        desc: '" There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour "'
    },
    {
        class: 'mt-8',
        image: client3,
        name: 'Calvin Carlo',
        time: '6th May 2022 at 01:25 pm',
        reply: 'Reply',
        desc: '" There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour "'
    },
    {
        class: 'mt-8',
        image: client4,
        name: 'Calvin Carlo',
        time: '6th May 2022 at 01:25 pm',
        reply: 'Reply',
        desc: '" There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour "'
    },
]

export const helpData = [
    {
        icon: FaRegQuestionCircle,
        title: "FAQs",
        desc: "The phrasal sequence of the is now so that many campaign and benefit",
        link: "/helpcenter-faqs"
    },
    {
        icon: MdOutlineCollectionsBookmark,
        title: "Guides / Support",
        desc: "The phrasal sequence of the is now so that many campaign and benefit",
        link: "/helpcenter-guides",
    },
    {
        icon: HiOutlineCog6Tooth,
        title: "Support Request",
        desc: "The phrasal sequence of the is now so that many campaign and benefit",
        link: "/helpcenter-support",
    },
]

export const constructionAbout = [
    {
        icon: TbPackage,
        title: "Choose the package",
        desc: "Choosing a package comes first when ordering any of the IG Champ Instagram services. Different available options allow you to select the best according to your needs."
    },
    {
        icon: BsPencilSquare,
        title: "Requirements",
        desc: "We don’t need your sensitive information like password or anything else. You will be asked to provide a username and email. Once you provide the required detail, we will start working on your order straight away."
    },
    {
        icon: LiaMoneyCheckAltSolid,
        title: "Payment",
        desc: "Payment is the last step when ordering Instagram services from IG Champ. Submit your payment details as requested by the site. You will receive your high-quality IG followers or other services you ordered within a few seconds."
    },
    {
        icon: HiOutlineCog6Tooth,
        title: "Sit Back and Relax",
        desc: "Have you chosen the package and entered the required detail! Cheers! Your job ends here. Just relax and see how miraculously your Instagram grows with real followers."
    },
]
export const consultingAbout = [
    {
        icon: Icon.ThumbsUp,
        title: "Advantages",
        desc: "The primary benefit of buying Instagram followers is to increase one's presence or visibility. Your page has a better chance to show up in other users’ feeds when you have huge followers. As such more people and potential clients can see you in their feeds."
    },
    {
        icon: Icon.UserPlus,
        title: "Engagement",
        desc: "You can also boost your engagement level when you buy Instagram followers. An increased number of followers earns you more social proof, making you more trustworthy among others. You also earn the authority to encourage and engage others in any conversation on your posts."
    },
    {
        icon: Icon.Clock,
        title: "Time Saving",
        desc: "Purchasing Instagram followers is time-saving. You don't need to spend time trying to manually source followers for your page. A one-time high-quality IG followers purchase is all you need to save time, and have enough time for high-quality content creation."
    },
    {
        icon: Icon.ThumbsUp,
        title: "Quality Assured Services",
        desc: "With years of experience and result-driven social media services, we have earned a name in the industry. With us, you will get the real and active followers. In case followers drop, we will refill them unless you are delighted."
    },
    {
        icon: Icon.UserPlus,
        title: "Refund policy",
        desc: "We leave no stone unturned in providing you the most satisfying services. In case any of our customers are not satisfied with the quality of our service, or there is any legit reason. We will refund you."
    },
    {
        icon: Icon.Clock,
        title: "24-hour customer support",
        desc: "Our highly dedicated and professional team will always be available to answer any of your question you may have about our services. In case you feel inconvenience with order! Don’t hesitate to contact us!"
    },
]



export const categories = [
    {
        id: 1,
        name: "Instagram",
        slug: "instagram",
        sub: [{
            id: 10,
            name: "Followers"
        },
            {
                id: 11,
                name: "Likes"
            },
            {
                id: 12,
                name: "Views"
            },
            /*{
                id: 13,
                name: "Story Views"
            },
            {
                id: 14,
                name: "Reels Views"
            },
            {
                id: 15,
                name: "Reels Likes"
            }*/
        ]
    },
    {
        id: 2,
        name: "TikTok",
        slug: "tiktok",
        sub: [{
            id: 20,
            name: "Followers"
        },
            {
                id: 21,
                name: "Likes"
            },
            {
                id: 22,
                name: "Views"
            }
        ]
    },
    /*{
        id: 3,
        name: "Youtube",
        slug: "youtube",
        sub: [{
            id: 30,
            name: "Subscriber"
        },
            {
                id: 31,
                name: "Views"
            },
            {
                id: 32,
                name: "Likes"
            },
            {
                id: 33,
                name: "WatchTime"
            }
        ]
    },*/
    /*{
        id: 4,
        name: "Facebook",
        slug: "facebook",
        sub: [{
            id: 40,
            name: "Followers"
        },
            {
                id: 41,
                name: "Post Likes"
            },
            {
                id: 42,
                name: "Views"
            }
        ]
    },
    {
        id: 5,
        name: "SoundCloud",
        slug: "soundcloud",
        sub: [{
            id: 50,
            name: "Followers"
        },
            {
                id: 51,
                name: "Likes"
            },
            {
                id: 52,
                name: "Plays"
            }
        ]
    },*/
]

export const pricingTableData = [
    {
        id:1,
        cat:10,
        name:"100 IG Followers",
        price:2.69,
        link:"https://app.igchamp.com/product/100-ig-followers"
    },
    {
        id:2,
        cat:10,
        name:"250 IG Followers",
        price:3.99,
        link:"https://app.igchamp.com/product/250-ig-followers"
    },
    {
        id:3,
        cat:10,
        name:"500 IG Followers",
        price:5.99,
        link:"https://app.igchamp.com/product/500-ig-followers"
    },
    {
        id:4,
        cat:10,
        name:"1000 IG Followers",
        price:11.59,
        link:"https://app.igchamp.com/product/1000-ig-followers"
    },
    {
        id:5,
        cat:10,
        name:"2500 IG Followers",
        price:25.99,
        link:"https://app.igchamp.com/product/2500-ig-followers"
    },
    {
        id:6,
        cat:10,
        name:"5000 IG Followers",
        price:39.00,
        link:"https://app.igchamp.com/product/5000-ig-followers"
    },
    {
        id:7,
        cat:10,
        name:"10k IG Followers",
        price:55.00,
        link:"https://app.igchamp.com/product/10k-ig-followers"
    },
    {
        id:8,
        cat:10,
        name:"25k IG Followers",
        price:119.00,
        link:"https://app.igchamp.com/product/25k-ig-followers"
    },
    {
        id:9,
        cat:11,
        name:"100 IG Likes",
        price:0.99,
        link:"https://app.igchamp.com/product/100-ig-likes"
    },
    {
        id:10,
        cat:11,
        name:"250 IG Likes",
        price:2.59,
        link:"https://app.igchamp.com/product/250-ig-likes"
    },
    {
        id:11,
        cat:11,
        name:"500 IG Likes",
        price:4.99,
        link:"https://app.igchamp.com/product/500-ig-likes"
    },
    {
        id:12,
        cat:11,
        name:"1000 IG Likes",
        price:10,
        link:"https://app.igchamp.com/product/1000-ig-likes"
    },
    {
        id:13,
        cat:11,
        name:"2500 IG Likes",
        price:23,
        link:"https://app.igchamp.com/product/2500-ig-likes"
    },
    {
        id:14,
        cat:11,
        name:"5000 IG Likes",
        price:35.00,
        link:"https://app.igchamp.com/product/5000-ig-likes"
    },
    {
        id:15,
        cat:11,
        name:"10k IG Likes",
        price:63.00,
        link:"https://app.igchamp.com/product/10k-ig-likes"
    },
    {
        id:16,
        cat:11,
        name:"25k IG Likes",
        price:119.00,
        link:"https://app.igchamp.com/product/25k-ig-likes"
    },
    {
        id:17,
        cat:12,
        name:"100 IG Views",
        price:0.79,
        link:"https://app.igchamp.com/product/100-ig-views"
    },
    {
        id:18,
        cat:12,
        name:"250 IG Views",
        price:1.39,
        link:"https://app.igchamp.com/product/250-ig-views"
    },
    {
        id:19,
        cat:12,
        name:"500 IG Views",
        price:2,
        link:"https://app.igchamp.com/product/500-ig-views"
    },
    {
        id:20,
        cat:12,
        name:"1000 IG Views",
        price:4,
        link:"https://app.igchamp.com/product/1000-ig-views"
    },
    {
        id:21,
        cat:12,
        name:"2500 IG Views",
        price:6.99,
        link:"https://app.igchamp.com/product/2500-ig-views"
    },
    {
        id:22,
        cat:12,
        name:"5000 IG Views",
        price:14.99,
        link:"https://app.igchamp.com/product/5000-ig-views"
    },
    {
        id:23,
        cat:12,
        name:"10k IG Views",
        price:23.00,
        link:"https://app.igchamp.com/product/10k-ig-views"
    },
    {
        id:24,
        cat:12,
        name:"25k IG Views",
        price:49.99,
        link:"https://app.igchamp.com/product/25k-ig-views"
    },
    /*{
        id:25,
        cat:13,
        name:"100 IG Story Views",
        price:1.89,
        link:"https://app.igchamp.com/product/100-ig-story-views"
    },
    {
        id:26,
        cat:13,
        name:"250 IG Story Views",
        price:2.79,
        link:"https://app.igchamp.com/product/250-ig-story-views"
    },
    {
        id:27,
        cat:13,
        name:"500 IG Story Views",
        price:4.39,
        link:"https://app.igchamp.com/product/500-ig-story-views"
    },
    {
        id:28,
        cat:13,
        name:"1000 IG Story Views",
        price:8.49,
        link:"https://app.igchamp.com/product/1000-ig-story-views"
    },
    {
        id:29,
        cat:13,
        name:"2500 IG Story Views",
        price:11.39,
        link:"https://app.igchamp.com/product/2500-ig-story-views"
    },
    {
        id:30,
        cat:13,
        name:"5000 IG Story Views",
        price:14,
        link:"https://app.igchamp.com/product/5000-ig-story-views"
    },
    {
        id:31,
        cat:13,
        name:"10k IG Story Views",
        price:24.00,
        link:"https://app.igchamp.com/product/10k-ig-story-views"
    },
    {
        id:32,
        cat:13,
        name:"25k IG Story Views",
        price:47.99,
        link:"https://app.igchamp.com/product/25k-ig-story-views"
    },
    {
        id:33,
        cat:14,
        name:"500 IG Reels Views",
        price:2.69,
        link:"https://app.igchamp.com/product/500-ig-reels-views"
    },
    {
        id:34,
        cat:14,
        name:"1000 IG Reels Views",
        price:4.79,
        link:"https://app.igchamp.com/product/1000-ig-reels-views"
    },
    {
        id:35,
        cat:14,
        name:"2500 IG Reels Views",
        price:9.89,
        link:"https://app.igchamp.com/product/2500-ig-reels-views"
    },
    {
        id:36,
        cat:14,
        name:"5000 IG Reels Views",
        price:14.89,
        link:"https://app.igchamp.com/product/5000-ig-reels-views"
    },
    {
        id:37,
        cat:14,
        name:"10K IG Reels Views",
        price:23.99,
        link:"https://app.igchamp.com/product/10K-ig-reels-views"
    },
    {
        id:38,
        cat:14,
        name:"25k IG Reels Views",
        price:48.99,
        link:"https://app.igchamp.com/product/25k-ig-reels-views"
    },
    {
        id:39,
        cat:14,
        name:"50k IG Reels Views",
        price:93.00,
        link:"https://app.igchamp.com/product/50k-ig-reels-views"
    },
    {
        id:40,
        cat:14,
        name:"100k IG Reels Views",
        price:179,
        link:"https://app.igchamp.com/product/100k-ig-reels-views"
    },
    {
        id:41,
        cat:15,
        name:"100 IG Reels Likes",
        price:0.99,
        link:"https://app.igchamp.com/product/100-ig-reels-likes"
    },
    {
        id:42,
        cat:15,
        name:"250 IG Reels Likes",
        price:1.99,
        link:"https://app.igchamp.com/product/250-ig-reels-likes"
    },
    {
        id:43,
        cat:15,
        name:"500 IG Reels Likes",
        price:3.89,
        link:"https://app.igchamp.com/product/500-ig-reels-likes"
    },
    {
        id:44,
        cat:15,
        name:"1000 IG Reels Likes",
        price:6.49,
        link:"https://app.igchamp.com/product/1000-ig-reels-likes"
    },
    {
        id:45,
        cat:15,
        name:"3000 IG Reels Likes",
        price:13.99,
        link:"https://app.igchamp.com/product/3000-ig-reels-likes"
    },
    {
        id:46,
        cat:15,
        name:"5k IG Reels Likes",
        price:23.99,
        link:"https://app.igchamp.com/product/5k-ig-reels-likes"
    },
    {
        id:47,
        cat:15,
        name:"10k IG Reels Likes",
        price:34.00,
        link:"https://app.igchamp.com/product/10k-ig-reels-likes"
    },
    {
        id:48,
        cat:15,
        name:"25k IG Reels Likes",
        price:64,
        link:"https://app.igchamp.com/product/25k-ig-reels-likes"
    },*/
    /*TikTok Services*/
    {
        id:49,
        cat:20,
        name:"100 TikTok Followers",
        price:1.99,
        link:"https://app.igchamp.com/product/100-tiktok-followers"
    },
    {
        id:50,
        cat:20,
        name:"500 TikTok Followers",
        price:5.99,
        link:"https://app.igchamp.com/product/250-tiktok-followers"
    },
    {
        id:51,
        cat:20,
        name:"1000 TikTok Followers",
        price:13.99,
        link:"https://app.igchamp.com/product/500-tiktok-followers"
    },
    {
        id:52,
        cat:20,
        name:"10K TikTok Followers",
        price:83.99,
        link:"https://app.igchamp.com/product/1000-tiktok-followers"
    },
    {
        id:53,
        cat:21,
        name:"100 TikTok Likes",
        price:1.49,
        link:"https://app.igchamp.com/product/100-tiktok-likes"
    },
    {
        id:54,
        cat:21,
        name:"250 TikTok Likes",
        price:2.99,
        link:"https://app.igchamp.com/product/250-tiktok-likes"
    },
    {
        id:55,
        cat:21,
        name:"500 TikTok Likes",
        price:4.99,
        link:"https://app.igchamp.com/product/500-tiktok-likes"
    },
    {
        id:56,
        cat:21,
        name:"1000 TikTok Likes",
        price:8.99,
        link:"https://app.igchamp.com/product/1000-tiktok-likes"
    },
    {
        id:57,
        cat:21,
        name:"2500 TikTok Likes",
        price:20.00,
        link:"https://app.igchamp.com/product/2500-tiktok-likes"
    },
    {
        id:58,
        cat:21,
        name:"5000 TikTok Likes",
        price:34.00,
        link:"https://app.igchamp.com/product/5000-tiktok-likes"
    },
    {
        id:59,
        cat:21,
        name:"10k TikTok Likes",
        price:59.99,
        link:"https://app.igchamp.com/product/10k-tiktok-likes"
    },
    {
        id:60,
        cat:21,
        name:"25k TikTok Likes",
        price:114.99,
        link:"https://app.igchamp.com/product/25k-tiktok-likes"
    },

    {
        id:61,
        cat:22,
        name:"500 TikTok Views",
        price:2.49,
        link:"https://app.igchamp.com/product/500-tiktok-views"
    },
    {
        id:62,
        cat:22,
        name:"1000 TikTok Views",
        price:4.49,
        link:"https://app.igchamp.com/product/1000-tiktok-views"
    },
    {
        id:63,
        cat:22,
        name:"5000 TikTok Views",
        price:20.00,
        link:"https://app.igchamp.com/product/5000-tiktok-views"
    },
    {
        id:64,
        cat:22,
        name:"10K TikTok Views",
        price:34.00,
        link:"https://app.igchamp.com/product/10k-tiktok-views"
    },
    /*YouTube Services*/

    /*{
        id:65,
        cat:30,
        name:"100 YouTube Subscribers",
        price:2.79,
        link:"https://app.igchamp.com/product/100-youtube-subscribers"
    },
    {
        id:66,
        cat:30,
        name:"250 YouTube Subscribers",
        price:6.79,
        link:"https://app.igchamp.com/product/250-youtube-subscribers"
    },
    {
        id:67,
        cat:30,
        name:"500 YouTube Subscribers",
        price:12.69,
        link:"https://app.igchamp.com/product/500-youtube-subscribers"
    },
    {
        id:68,
        cat:30,
        name:"1000 YouTube Subscribers",
        price:24.99,
        link:"https://app.igchamp.com/product/1000-youtube-subscribers"
    },


    {
        id:69,
        cat:30,
        name:"2000 YouTube Subscribers",
        price:47.99,
        link:"https://app.igchamp.com/product/2000-youtube-subscribers"
    },
    {
        id:70,
        cat:30,
        name:"3000 YouTube Subscribers",
        price:71.99,
        link:"https://app.igchamp.com/product/3000-youtube-subscribers"
    },
    {
        id:71,
        cat:30,
        name:"5000 YouTube Subscribers",
        price:103.99,
        link:"https://app.igchamp.com/product/5000-youtube-subscribers"
    },
    {
        id:72,
        cat:30,
        name:"10K YouTube Subscribers",
        price:219.99,
        link:"https://app.igchamp.com/product/10k-youtube-subscribers"
    },


    {
        id:73,
        cat:31,
        name:"250 YouTube Views",
        price:1.39,
        link:"https://app.igchamp.com/product/250-youtube-views"
    },
    {
        id:74,
        cat:31,
        name:"500 YouTube Views",
        price:2.69,
        link:"https://app.igchamp.com/product/500-youtube-views"
    },
    {
        id:75,
        cat:31,
        name:"1000 YouTube Views",
        price:5.39,
        link:"https://app.igchamp.com/product/1000-youtube-views"
    },
    {
        id:76,
        cat:31,
        name:"2500 YouTube Views",
        price:11.99,
        link:"https://app.igchamp.com/product/2500-youtube-views"
    },


    {
        id:77,
        cat:31,
        name:"5000 YouTube Views",
        price:26.99,
        link:"https://app.igchamp.com/product/5000-youtube-views"
    },
    {
        id:78,
        cat:31,
        name:"10K YouTube Views",
        price:49.99,
        link:"https://app.igchamp.com/product/10k-youtube-views"
    },
    {
        id:79,
        cat:31,
        name:"25K YouTube Views",
        price:134.99,
        link:"https://app.igchamp.com/product/25k-youtube-views"
    },
    {
        id:80,
        cat:31,
        name:"50K YouTube Views",
        price:269.99,
        link:"https://app.igchamp.com/product/10k-youtube-views"
    },
    {
        id:81,
        cat:32,
        name:"50 YouTube Likes",
        price:1.89,
        link:"https://app.igchamp.com/product/50-youtube-likes"
    },
    {
        id:82,
        cat:32,
        name:"100 YouTube Likes",
        price:3.49,
        link:"https://app.igchamp.com/product/100-youtube-likes"
    },
    {
        id:83,
        cat:32,
        name:"250 YouTube Likes",
        price:8.79,
        link:"https://app.igchamp.com/product/250-youtube-likes"
    },
    {
        id:84,
        cat:32,
        name:"500 YouTube Likes",
        price:17.49,
        link:"https://app.igchamp.com/product/500-youtube-likes"
    },


    {
        id:85,
        cat:32,
        name:"1000 YouTube Likes",
        price:33.99,
        link:"https://app.igchamp.com/product/1000-youtube-likes"
    },
    {
        id:86,
        cat:32,
        name:"2500 YouTube Likes",
        price:83.99,
        link:"https://app.igchamp.com/product/2500-youtube-likes"
    },
    {
        id:87,
        cat:32,
        name:"5K YouTube Likes",
        price:169.99,
        link:"https://app.igchamp.com/product/5k-youtube-likes"
    },
    {
        id:88,
        cat:32,
        name:"10K YouTube Likes",
        price:249.99,
        link:"https://app.igchamp.com/product/10k-youtube-likes"
    },
    {
        id:89,
        cat:33,
        name:"100 YouTube WatchTime",
        price:4.69,
        link:"https://app.igchamp.com/product/100-youtube-watchtime"
    },
    {
        id:90,
        cat:33,
        name:"250 YouTube WatchTime",
        price:9.49,
        link:"https://app.igchamp.com/product/250-youtube-watchtime"
    },
    {
        id:91,
        cat:33,
        name:"500 YouTube WatchTime",
        price:17.99,
        link:"https://app.igchamp.com/product/500-youtube-watchtime"
    },
    {
        id:92,
        cat:33,
        name:"750 YouTube WatchTime",
        price:24.99,
        link:"https://app.igchamp.com/product/750-youtube-watchtime"
    },


    {
        id:93,
        cat:33,
        name:"1000 YouTube WatchTime",
        price:30.99,
        link:"https://app.igchamp.com/product/1000-youtube-watchtime"
    },
    {
        id:94,
        cat:33,
        name:"2000 YouTube WatchTime",
        price:59.99,
        link:"https://app.igchamp.com/product/2000-youtube-watchtime"
    },
    {
        id:95,
        cat:33,
        name:"3000 YouTube WatchTime",
        price:87.99,
        link:"https://app.igchamp.com/product/3000-youtube-watchtime"
    },
    {
        id:96,
        cat:33,
        name:"4000 YouTube WatchTime",
        price:115.99,
        link:"https://app.igchamp.com/product/4000-youtube-watchtime"
    },
    /!*Facebook Services*!/
    {
        id:97,
        cat:40,
        name:"100 Facebook Followers",
        price:1.89,
        link:"https://app.igchamp.com/product/100-facebook-followers"
    },
    {
        id:98,
        cat:40,
        name:"250 Facebook Followers",
        price:4.29,
        link:"https://app.igchamp.com/product/250-facebook-followers"
    },
    {
        id:99,
        cat:40,
        name:"500 Facebook Followers",
        price:7.89,
        link:"https://app.igchamp.com/product/500-facebook-followers"
    },
    {
        id:100,
        cat:40,
        name:"1000 Facebook Followers",
        price:13.99,
        link:"https://app.igchamp.com/product/1000-facebook-followers"
    },
    {
        id:101,
        cat:40,
        name:"2500 Facebook Followers",
        price:23.49,
        link:"https://app.igchamp.com/product/2500-facebook-followers"
    },
    {
        id:102,
        cat:40,
        name:"5000 Facebook Followers",
        price:41.99,
        link:"https://app.igchamp.com/product/5000-facebook-followers"
    },
    {
        id:103,
        cat:40,
        name:"10k Facebook Followers",
        price:78.99,
        link:"https://app.igchamp.com/product/10k-facebook-followers"
    },
    {
        id:104,
        cat:40,
        name:"25k Facebook Followers",
        price:144.99,
        link:"https://app.igchamp.com/product/25k-facebook-followers"
    },
    {
        id:105,
        cat:41,
        name:"100 Facebook Post Likes",
        price:0.79,
        link:"https://app.igchamp.com/product/100-facebook-post-likes"
    },
    {
        id:106,
        cat:41,
        name:"250 Facebook Post Likes",
        price:1.59,
        link:"https://app.igchamp.com/product/250-facebook-post-likes"
    },
    {
        id:107,
        cat:41,
        name:"500 Facebook Post Likes",
        price:3.19,
        link:"https://app.igchamp.com/product/500-facebook-post-likes"
    },
    {
        id:108,
        cat:41,
        name:"1000 Facebook Post Likes",
        price:5.99,
        link:"https://app.igchamp.com/product/1000-facebook-post-likes"
    },
    {
        id:109,
        cat:41,
        name:"2500 Facebook Post Likes",
        price:12.49,
        link:"https://app.igchamp.com/product/2500-facebook-post-likes"
    },
    {
        id:110,
        cat:41,
        name:"5000 Facebook Post Likes",
        price:24.99,
        link:"https://app.igchamp.com/product/5000-facebook-post-likes"
    },
    {
        id:111,
        cat:41,
        name:"10k Facebook Post Likes",
        price:48.99,
        link:"https://app.igchamp.com/product/10k-facebook-post-likes"
    },
    {
        id:112,
        cat:41,
        name:"25k Facebook Post Likes",
        price:119.99,
        link:"https://app.igchamp.com/product/25k-facebook-post-likes"
    },
    {
        id:113,
        cat:42,
        name:"500 Facebook Video Views",
        price:1.69,
        link:"https://app.igchamp.com/product/500-facebook-video-views"
    },
    {
        id:114,
        cat:42,
        name:"1000 Facebook Video Views",
        price:3.19,
        link:"https://app.igchamp.com/product/1000-facebook-video-views"
    },
    {
        id:115,
        cat:42,
        name:"2500 Facebook Video Views",
        price:6.29,
        link:"https://app.igchamp.com/product/2500-facebook-video-views"
    },
    {
        id:116,
        cat:42,
        name:"5000 Facebook Video Views",
        price:11.99,
        link:"https://app.igchamp.com/product/5000-facebook-video-views"
    },
    {
        id:117,
        cat:42,
        name:"10K Facebook Video Views",
        price:22.19,
        link:"https://app.igchamp.com/product/10k-facebook-video-views"
    },
    {
        id:118,
        cat:42,
        name:"25K Facebook Video Views",
        price:42.99,
        link:"https://app.igchamp.com/product/25k-facebook-video-views"
    },
    {
        id:119,
        cat:42,
        name:"50k Facebook Video Views",
        price:87.49,
        link:"https://app.igchamp.com/product/50k-facebook-video-views"
    },
    {
        id:112,
        cat:42,
        name:"100k Facebook Video Views",
        price:174.99,
        link:"https://app.igchamp.com/product/100k-facebook-video-views"
    },
    /!*SoundCloud Services*!/
    {
        id:113,
        cat:50,
        name:"100 SoundCloud Followers",
        price:1.19,
        link:"https://app.igchamp.com/product/100-soundcloud-followers"
    },
    {
        id:114,
        cat:50,
        name:"250 SoundCloud Followers",
        price:2.49,
        link:"https://app.igchamp.com/product/250-soundcloud-followers"
    },
    {
        id:115,
        cat:50,
        name:"500 SoundCloud Followers",
        price:4.29,
        link:"https://app.igchamp.com/product/500-soundcloud-followers"
    },
    {
        id:116,
        cat:50,
        name:"1000 SoundCloud Followers",
        price:7.49,
        link:"https://app.igchamp.com/product/1000-soundcloud-followers"
    },
    {
        id:117,
        cat:50,
        name:"2500 SoundCloud Followers",
        price:17.79,
        link:"https://app.igchamp.com/product/2500-soundcloud-followers"
    },
    {
        id:118,
        cat:50,
        name:"5K SoundCloud Followers",
        price:34.49,
        link:"https://app.igchamp.com/product/5k-soundcloud-followers"
    },
    {
        id:119,
        cat:50,
        name:"10k SoundCloud Followers",
        price:67.49,
        link:"https://app.igchamp.com/product/10k-soundcloud-followers"
    },
    {
        id:120,
        cat:50,
        name:"25k SoundCloud Followers",
        price:219.99,
        link:"https://app.igchamp.com/product/25k-soundcloud-followers"
    },
    {
        id:121,
        cat:51,
        name:"100 SoundCloud Likes",
        price:1.29,
        link:"https://app.igchamp.com/product/100-soundcloud-likes"
    },
    {
        id:122,
        cat:51,
        name:"250 SoundCloud Likes",
        price:1.89,
        link:"https://app.igchamp.com/product/250-soundcloud-likes"
    },
    {
        id:123,
        cat:51,
        name:"500 SoundCloud Likes",
        price:2.99,
        link:"https://app.igchamp.com/product/500-soundcloud-likes"
    },
    {
        id:124,
        cat:51,
        name:"1000 SoundCloud Likes",
        price:5.89,
        link:"https://app.igchamp.com/product/1000-soundcloud-likes"
    },
    {
        id:125,
        cat:51,
        name:"2500 SoundCloud Likes",
        price:13.99,
        link:"https://app.igchamp.com/product/2500-soundcloud-likes"
    },
    {
        id:126,
        cat:51,
        name:"5K SoundCloud Likes",
        price:25.99,
        link:"https://app.igchamp.com/product/5k-soundcloud-likes"
    },
    {
        id:127,
        cat:51,
        name:"10k SoundCloud Likes",
        price:49.99,
        link:"https://app.igchamp.com/product/10k-soundcloud-likes"
    },
    {
        id:128,
        cat:51,
        name:"25k SoundCloud Likes",
        price:129.99,
        link:"https://app.igchamp.com/product/25k-soundcloud-likes"
    },
    {
        id:129,
        cat:52,
        name:"500 SoundCloud Plays",
        price:0.59,
        link:"https://app.igchamp.com/product/500-soundcloud-plays"
    },
    {
        id:130,
        cat:52,
        name:"1000 SoundCloud Plays",
        price:0.79,
        link:"https://app.igchamp.com/product/1000-soundcloud-plays"
    },
    {
        id:131,
        cat:52,
        name:"2500 SoundCloud Plays",
        price:1.39,
        link:"https://app.igchamp.com/product/2500-soundcloud-plays"
    },
    {
        id:132,
        cat:52,
        name:"5K SoundCloud Plays",
        price:2.65,
        link:"https://app.igchamp.com/product/5k-soundcloud-plays"
    },
    {
        id:133,
        cat:52,
        name:"10K SoundCloud Plays",
        price:5.19,
        link:"https://app.igchamp.com/product/10k-soundcloud-plays"
    },
    {
        id:134,
        cat:52,
        name:"25K SoundCloud Plays",
        price:13.49,
        link:"https://app.igchamp.com/product/25k-soundcloud-plays"
    },
    {
        id:135,
        cat:52,
        name:"50k SoundCloud Plays",
        price:22.99,
        link:"https://app.igchamp.com/product/50k-soundcloud-plays"
    },
    {
        id:136,
        cat:52,
        name:"100k SoundCloud Plays",
        price:45.99,
        link:"https://app.igchamp.com/product/100k-soundcloud-plays"
    }*/
]



export const igFollowersFaq = [
    {
        id: 1,
        title: 'How to Buy Instagram Followers?',
        content: '<p>Purchasing Instagram followers with IGChamp is simple. Start by visiting our website and navigating the <b>Buy Instagram Followers</b> section. From there, select the package that best suits your needs and budget. Next, provide us with your Instagram username and any specific preferences you have regarding your followers, such as targeting a particular audience or demographic.</p>' +
            '<p>Once you\'ve filled in the necessary information, proceed to the checkout page to complete your purchase securely. After your payment is processed, our team will begin working on delivering your followers promptly. Sit back, relax, and watch as your Instagram following grows with real, engaged users from IGChamp.</p>'
    },
    {
        id: 2,
        title: 'Where to Buy Instagram Followers?',
        content: '<p>When it comes to buying Instagram followers, it\'s essential to choose a reputable and trustworthy provider like IGChamp. With a proven track record of delivering high-quality followers and excellent customer service, IGChamp stands out as a top choice for anyone looking to boost their Instagram presence.</p> <p>Unlike unreliable providers that may offer low-quality followers or use unethical tactics, IGChamp prioritizes authenticity and transparency. You can buy Instagram followers confidently from IGChamp, knowing you\'re investing in genuine growth that aligns with Instagram\'s guidelines.</p>'
    },
    {
        id: 3,
        title: 'How to Pay for Instagram Followers?',
        content: '<p>Paying for Instagram followers with IGChamp is convenient and secure. Once you\'ve selected your desired follower package, proceed to the checkout page, where various payment options are available. We accept major credit cards, debit cards, and other secure online payment methods to ensure a hassle-free transaction.<br> Choose your preferred payment method, enter your payment details, and follow the prompts to complete your purchase securely. Rest assured that your payment information is encrypted and protected, providing peace of mind. After your payment is confirmed, our team will deliver your followers promptly, helping you grow your Instagram presence effectively.<p>'
    },
    {
        id: 4,
        title: 'Why Do Brands Choose IGChamp?',
        content: 'Brands choose IGChamp for one simple reason: we deliver results that exceed expectations. Our commitment to excellence and our proven track record of success sets us apart as the go-to choice for Instagram growth. With IGChamp, brands can trust that they\'re getting more than just followers—they\'re gaining a strategic partner dedicated to their success.' +
            '<p>Here\'s why brands love IGChamp:</p>' +
'                                   <ul className="list-none text-slate-400 mt-4 space-x-1">\n' +
'                                        <li className="mb-1 flex items-center"><BsCheckCircle className="text-indigo-600 text-base me-2"/><b>Quality Assurance:</b> We pride ourselves on delivering high-quality, real followers who engage authentically with your content. Our rigorous screening process ensures that every follower you receive is genuine and aligned with your target audience.</li>' +
'                                        <li className="mb-1 flex items-center"><BsCheckCircle className="text-indigo-600 text-base me-2"/><b>Transparency:</b> We believe in transparency every step of the way. From our pricing structure to our follower acquisition methods, we\'re upfront and honest about how we operate. With IGChamp, you\'ll always know exactly what you\'re getting.</li>' +
'                                        <li className="mb-1 flex items-center"><BsCheckCircle className="text-indigo-600 text-base me-2"/><b>Proven Results:</b> Don\'t just take our word for it—our track record speaks for itself. Countless brands have experienced significant growth and success with IGChamp, making us the trusted choice for Instagram growth.</li>' +
'                                    </ul>'
    },
    {
        id: 5,
        title: 'How does it work ?',
        content: 'There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form.'
    },
    {
        id: 6,
        title: 'Do I need a designer to use Techwind ?',
        content: 'There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form.'
    },
    {
        id: 7,
        title: 'What do I need to do to start selling ?',
        content: 'There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form.'
    },
    {
        id: 8,
        title: 'What happens when I receive an order ?',
        content: 'There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form.'
    }
]

export const socialPresence = [
    {
        icon: Icon.CheckCircle,
        title: "Reviews for Verification",
        desc: "At IGChamp, transparency and trust are critical factors of our services. To ensure you feel confident in your decision to work with us, we encourage you to check out our reviews from satisfied clients. These testimonials verify our commitment to quality and the effectiveness of our Instagram follower packages. Hear firsthand from users who have experienced significant social growth and engagement by choosing IGChamp. Our reviews reflect our dedication to delivering real, lasting results while maintaining the highest service standards. Join our community of happy customers and see why IGChamp is the trusted choice for purchasing IG followers."
    },
    {
        icon: Icon.Users,
        title: "Guaranteed High-Quality Followers",
        desc: "At IGChamp, we don't just promise followers—we promise high-quality followers who genuinely engage with your content. Our commitment to quality means that every follower you gain through our services is a real person with an active presence on Instagram. We never use bots or fake accounts, ensuring your follower count grows organically and authentically. With our rigorous screening process and dedication to delivering results, you can trust that the followers you receive will contribute meaningfully to your Instagram presence. Experience the difference of guaranteed high-quality followers with IGChamp and watch your engagement meet your goals."
    },
    {
        icon: Icon.Instagram,
        title: "Instagram Compliant",
        desc: "At IGChamp, we prioritize your account's safety by offering services that comply with Instagram's guidelines. Our approach ensures you receive genuine, active followers without penalties or suspensions. We strictly avoid using bots or fake accounts, focusing instead on delivering quality followers that aligns with Instagram's terms of service. With IGChamp, you can confidently boost your Instagram following, knowing that your account is in safe, reliable hands and that your growth is sustainable and compliant with Instagram's rules."
    },
    {
        icon: Icon.Headphones,
        title: "Best Customer Support",
        desc: "At IGChamp, we pride ourselves on providing the best customer support in the industry. Our dedicated team is here to assist you with every step, from selecting the right package to answering any questions or concerns. Whether you need help with your order, advice on growing your Instagram presence, or assistance with any issues. Our friendly and knowledgeable support staff are always ready to lend a helping hand. We understand the importance of prompt and effective communication, so we are here to provide timely responses and personalized solutions to satisfy you."
    },
]
export const socialViews = [
    {
        icon: Icon.Codesandbox,
        title: "Instant Delivery",
        desc: "Experience instant delivery with IGChamp’s Instagram views service. As soon as you place your order, watch your view count increase rapidly, enhancing your content’s visibility and engagement instantly. Our efficient system ensures prompt delivery without compromising on quality or authenticity. Whether you’re launching a new campaign or seeking to boost your social proof, our instant delivery mechanism caters to your immediate needs. Therefore, you can always trust IGChamp for reliable and quick results. We ensure that our services align with your Instagram growth strategy. So, it is time to start seeing the impact of instant delivery on your profile instantly."
    },
    {
        icon: Icon.Send,
        title: "Safe and Proven Views",
        desc: "Ensure your Instagram growth with IGChamp’s safe and proven views. We prioritize authenticity and security, delivering views that enhance your profile without risks. Hence, we cater proven methods to guarantee genuine engagement, adhering to Instagram’s guidelines to safeguard your account. Increase your credibility and attract organic traffic with views that reflect real user interaction. We ensure every view contributes to your social media success without compromising your account’s integrity. Choose IGChamp for safe, reliable, and effective Instagram growth strategies tailored to your needs."
    },
    {
        icon: Icon.Star,
        title: "Instagram Compliant",
        desc: "Stay Instagram compliant with IGChamp’s services. Our strategies adhere to Instagram’s guidelines, ensuring your account remains safe and secure. We deliver engagement that aligns with platform rules, enhancing your profile’s visibility organically. Whatever your business goals are, our compliant approach guarantees effective results without risking penalties. Trust IGChamp to provide ethical and sustainable methods that support long-term growth on Instagram. With us, you can confidently increase your reach and engagement while maintaining a positive reputation on the platform. Choose Instagram compliance with IGChamp for a reliable and successful social media strategy."
    },
    {
        icon: Icon.Star,
        title: "Affordable Packages",
        desc: "Discover affordable packages tailored to your Instagram needs with IGChamp. We offer a range of budget-friendly options designed to boost your profile’s visibility and engagement without breaking the bank. Whether you’re a small business, aspiring influencer, or established brand, our packages cater to every requirement. Enjoy competitive pricing with transparent terms, ensuring you get the best value for your investment. Choose from customizable plans that fit your goals and budget, empowering you to achieve social media success effortlessly. With IGChamp’s affordable packages, you can enhance your Instagram presence and attract a larger audience cost-effectively."
    },
]