import React, {useState} from 'react'
import {Link} from 'react-router-dom';
import {Helmet} from "react-helmet";
import Navbar from '../component/Navbar/navbar'
import Footer from '../component/Footer/footer';
import {Bs0CircleFill} from "react-icons/bs";
import {BiArrowToRight} from "react-icons/bi";

import {MdKeyboardArrowRight, FaArrowRight, BsCheckCircle, FaArrowDown} from "../assets/icons/icons"
import {accordionData, consultingAbout, igFollowersFaq, pricingTableData, socialPresence} from '../data/dataTwo';
import shree_logo from "../assets/images/igchamp/select-package.png";
import google_logo from "../assets/images/igchamp/put-info.png";
import lenovo_logo from "../assets/images/igchamp/payment.png";
import circle_logo from "../assets/images/igchamp/deliver-order.png";
import classic02 from "../assets/images/igchamp/buy-instagram-followers.png";

export default function PageTerms() {
    const [activeIndex, setActiveIndex] = useState(0);
    const [pricingTable, setpricingTable] = useState(pricingTableData.filter((element) => {
        return element.cat === 10;
    }))
    const toggleAccordion = (index) => {
        if (activeIndex === index) {
            setActiveIndex(0);
        } else {
            setActiveIndex(index);
        }
    }
    let accordionData1 = igFollowersFaq.filter((x) => x.id < 5);
    let accordionData2 = igFollowersFaq.filter((x) => x.id > 4)
    return (
        <>
            <Helmet>
                <title>IGchamp -Terms of Services</title>
                <meta name="description"
                      content=""/>
                <meta property="og:title" content="IGchamp -Terms of Services"/>
                <meta property="og:description"
                      content=""/>
                <meta name="keywords" content=""/>
                <meta property="og:image" content="LINK TO THE IMAGE FILE"/>
                <meta property="og:url" content="/term-of-service"/>
                <meta name="robots" content="noindex,nofollow" />
            </Helmet>
            <Navbar/>

            <section className="relative table w-full py-32 lg:py-40 bg-gray-50 dark:bg-slate-800">
                <div className="container relative">
                    <div className="grid grid-cols-1 text-center mt-10">
                        <h1 className="text-3xl leading-normal font-semibold">Igchamp - Term of Service</h1>
                        <p>Boost your Instagram presence with Igchamp – your trusted partner for buying real followers
                            at affordable prices.</p>
                    </div>
                </div>
                <div className="absolute text-center z-10 bottom-5 start-0 end-0 mx-3">
                    <ul className="tracking-[0.5px] mb-0 inline-flex space-x-1">
                        <li className="inline-block uppercase text-[13px] font-bold duration-500 ease-in-out hover:text-indigo-600">
                            <Link to="/">Home</Link></li>
                        <li className="inline-block text-base mx-0.5 ltr:rotate-0 rtl:rotate-180"><MdKeyboardArrowRight
                            className="text-xl"/></li>
                        <li className="inline-block uppercase text-[13px] font-bold duration-500 ease-in-out hover:text-indigo-600">
                            <Link>Igchamp</Link></li>
                        <li className="inline-block text-base mx-0.5 ltr:rotate-0 rtl:rotate-180"><MdKeyboardArrowRight
                            className="text-xl"/></li>
                        <li className="inline-block uppercase text-[13px] font-bold duration-500 ease-in-out text-indigo-600"
                            aria-current="page">Term of Service
                        </li>
                    </ul>
                </div>
            </section>


            <section className="relative overflow-hidden pb-32 ">
                    <div class="container">
                        <p class="text-80  mx-auto mt-5 text-auto">This is the most recent Terms and Conditions agreement by Social Pro as of 26th Jun 2022. Our website and services are provided by <b>Igchamp UK</b>.</p>
                    </div>
                    <div class="container">
                        <p class="text-80  mx-auto mt-5 text-auto">By accessing our website, you agree to be bound by the website Terms and Conditions, Refund Policy, Privacy Policy, and all the laws and regulations by us. You agree that you are responsible to obey all the applicable laws. You agree that by using our services, you clearly understand and decide what you are buying from Igchamp. You agree that you will not report any fraudulent dispute via PayPal, Credit card, or Bank. If you disagree with all the terms of services for this website, then no need to accept this agreement. And we will recommend you to not buy anything from Igchamp. Any further update or modification in our agreement will replace all former terms and conditions.</p>
                    </div>
                    <div class="container">
                        <h2 class="mb-4 text-2xl lg:leading-normal leading-normal mt-3">Our Terms:</h2>
                    </div>
                    <div class="container">
                        <ul class="list-none mb-0">
                            <li class=" flex mt-2"><BiArrowToRight className="text-indigo-600 text-base me-2"/>Igchamp will not be responsible for any kind of damages suffered by you. We do not make a guarantee of any kind, communicative or implicit, for our provided services. As our website uses the internet to deliver services, we cannot guarantee about uptime or availability of our site.</li>
                            <li class=" flex mt-2"><BiArrowToRight className="text-indigo-600 text-base me-2"/>Igchamp is not affiliated with Instagram, TikTok, Facebook, or any other social platform in any way.</li>
                            <li class=" flex mt-2"><BiArrowToRight className="text-indigo-600 text-base me-2"/>Igchamp reserves the right to modify, suspend, or withdraw either partially or fully of our service or content at any time without notifying you or inciting any answerability. It is the sole responsibility of our customers to check our Terms update.</li>
                            <li class=" flex mt-2"><BiArrowToRight className="text-indigo-600 text-base me-2"/>As a customer of Igchamp, you are subjected to comply with applicable laws and legislation.</li>
                            <li class=" flex mt-2"><BiArrowToRight className="text-indigo-600 text-base me-2"/>Using our website and services, you agree to this agreement, and your age is at least 13.</li>
                            <li class=" flex mt-2"><BiArrowToRight className="text-indigo-600 text-base me-2"/>It is the sole responsibility of our consumers to check updates about our terms and conditions.</li>
                            <li class=" flex mt-2"><BiArrowToRight className="text-indigo-600 text-base me-2"/>We do not guarantee you to provide the expected number of likes, followers, or views, but we always try to deliver an extra quantity to put a smile on our customer’s faces. In case, you receive less quantity, we can contact our customer support.</li>
                            <li class=" flex mt-2"><BiArrowToRight className="text-indigo-600 text-base me-2"/>You will follow all the agreements made with Social media websites while using our services and website.</li>
                            <li class=" flex mt-2"><BiArrowToRight className="text-indigo-600 text-base me-2"/>We strive for excellence but cannot guarantee to provide eternal, uninterrupted, or error-free operation of the services.</li>
                            <li class=" flex mt-2"><BiArrowToRight className="text-indigo-600 text-base me-2"/>Please note that we charge only in GBP to follow the market. You can pay only in GBP.</li>
                            <li class=" flex mt-2"><BiArrowToRight className="text-indigo-600 text-base me-2"/>Igchamp can make any changes on this website at any time without any notice. But, we do not commit to updating the materials.</li>
                            <li class=" flex mt-2"><BiArrowToRight className="text-indigo-600 text-base me-2"/> If you do not agree and acknowledge our Terms of services, then Igchamp advises you to not make any purchase from us.</li>
                            <li class=" flex mt-2"><BiArrowToRight className="text-indigo-600 text-base me-2"/>The use of our services will be only for promotional purposes.</li>
                            <li class=" flex mt-2"><BiArrowToRight className="text-indigo-600 text-base me-2"/>Any update to our policy may replace all the previous agreements.</li>
                            <li class=" flex mt-2"><BiArrowToRight className="text-indigo-600 text-base me-2"/>Any kind of typing or human error is reserved.</li>
                        </ul>
                    </div>
                    <div class="container">
                        <h3 class="mb-4 text-2xl lg:leading-normal leading-normal mt-3">Services:</h3>
                    </div>
                    <div class="container">
                        <ul class="list-none mb-0">
                            <li class=" flex mt-2"><BiArrowToRight className="text-indigo-600 text-base me-2"/>By purchasing from the Igchamp, you agree that our services will be used for your content promotion on Instagram and TikTok.</li>
                            <li class=" flex mt-2"><BiArrowToRight className="text-indigo-600 text-base me-2"/>We do not allow our consumers to use our services for promoting hate speech, bullying, any criminal act, or for any unfair means.</li>
                            <li class=" flex mt-2"><BiArrowToRight className="text-indigo-600 text-base me-2"/>The content published by you on Instagram and TikTok should follow the community guidelines of your respective platforms.</li>
                            <li class=" flex mt-2"><BiArrowToRight className="text-indigo-600 text-base me-2"/>Igchamp is not accountable for any kind of account suspension from the authorities of the social media platforms.</li>
                            <li class=" flex mt-2"><BiArrowToRight className="text-indigo-600 text-base me-2"/>We require your Instagram username to get the required information for the Instagram API. We do not store, give away or share your username with any third parties.</li>
                            <li class=" flex mt-2"><BiArrowToRight className="text-indigo-600 text-base me-2"/>No one can use our site’s property without getting our consent.</li>
                            <li class=" flex mt-2"><BiArrowToRight className="text-indigo-600 text-base me-2"/>No one can use our site’s property without getting our consent.</li>
                        </ul>
                    </div>
                    <div class="container">
                        <h3 class="mb-4 text-2xl lg:leading-normal leading-normal mt-3">Copyright:</h3>
                    </div>
                    <div class="container">
                        <ul class="list-none mb-0">
                            <li class=" flex mt-2"><BiArrowToRight className="text-indigo-600 text-base me-2"/>You are not allowed to copy any text or images used on the Igchamp website in any  case without the written consent of our representative.</li>
                            <li class=" flex mt-2"><BiArrowToRight className="text-indigo-600 text-base me-2"/>Igchamp does not warrant the accuracy, and ampleness of any of the material shared on our website</li>
                        </ul>
                    </div>
                    <div class="container">
                        <h3 class="mb-4 text-2xl lg:leading-normal leading-normal mt-3">Agreement:</h3>
                    </div>
                    <div class="container">
                        <ul class="list-none mb-0">
                            <li class=" flex mt-2"><BiArrowToRight className="text-indigo-600 text-base me-2"/>While using our services, you are not allowed to upload any content containing nudity or any stuff that is not allowed on Instagram, or the TikTok community.</li>
                            <li class=" flex mt-2"><BiArrowToRight className="text-indigo-600 text-base me-2"/>You are not allowed to misuse the system during our services.</li>
                            <li class=" flex mt-2"><BiArrowToRight className="text-indigo-600 text-base me-2"/>Any claim on the Igchamp website must be governed by the laws of the UK. General Terms and Conditions are applicable to the use of the website.</li>
                        </ul>
                    </div>
                    <div class="container">
                        <h3 class="mb-4 text-2xl lg:leading-normal leading-normal mt-3">Disclaimer:</h3>
                    </div>
                    <div class="container">
                        <ul class="list-none mb-0">
                            <li class=" flex mt-2"><BiArrowToRight className="text-indigo-600 text-base me-2"/>The materials on Igchamp’s website are provided “as is”.</li>
                            <li class=" flex mt-2"><BiArrowToRight className="text-indigo-600 text-base me-2"/>Igchamp makes no warranties, expressed or implied, and disclaims and negates all other warranties.</li>
                            <li class=" flex mt-2"><BiArrowToRight className="text-indigo-600 text-base me-2"/>Igchamp or its team is not responsible for any account suspension, photo deletion, or any action taken by Social Media websites, and negative comments, votes, reports, and DMs.</li>
                            <li class=" flex mt-2"><BiArrowToRight className="text-indigo-600 text-base me-2"/>We are not accountable or chargeable for your actions or their consequences. We do not believe that we are to blame for the suspension of your Instagram or TikTok account for any reason.</li>
                            <li class=" flex mt-2"><BiArrowToRight className="text-indigo-600 text-base me-2"/>You agree and acknowledge that you are using our services at your own risk. We are not responsible for any of the consequences that may occur because of using this website or service. It is 100% your own risk.</li>
                        </ul>
                    </div>
                    <div class="container">
                        <h3 class="mb-4 text-2xl lg:leading-normal leading-normal mt-3">Links:</h3>
                    </div>
                    <div class="container">
                        <ul class="list-none mb-0">
                            <li class=" flex mt-2"><BiArrowToRight className="text-indigo-600 text-base me-2"/>Igchamp website has not reviewed all the sites linked to it, and we are not responsible for the contents of these linked sites.</li>
                            <li class=" flex mt-2"><BiArrowToRight className="text-indigo-600 text-base me-2"/>Igchamp does not endorse all the inserted links on our website. Use of such linked sites is at the consumer’s own risk.</li>
                            <li class=" flex mt-2"><BiArrowToRight className="text-indigo-600 text-base me-2"/>Igchamp does not claim to make any demonstrations regarding the competence, expected outcomes, or authenticity of using its internet website. It does not assure to relate any such elements or sites linked to it.</li>
                        </ul>
                    </div>
                    <div class="container">
                        <h3 class="mb-4 text-2xl lg:leading-normal leading-normal mt-3">Subscription:</h3>
                    </div>
                    <div class="container">
                        <ul class="list-none mb-0">
                            <li class=" flex mt-2"><BiArrowToRight className="text-indigo-600 text-base me-2"/>Igchamp grants the right to ban your username and the IP address from our website in case of any fraudulent attempt to register a dispute, claim, unauthorized transaction, or chargeback.</li>
                            <li class=" flex mt-2"><BiArrowToRight className="text-indigo-600 text-base me-2"/>By shopping anything from Igchamp, you must agree to PayPal’s Terms of Services and will not break any state rules.</li>
                            <li class=" flex mt-2"><BiArrowToRight className="text-indigo-600 text-base me-2"/>While buying our services, you will not file a dishonest dispute via the payment processor.</li>
                        </ul>
                    </div>
                    <div class="container">
                        <h3 class="mb-4 text-2xl lg:leading-normal leading-normal mt-3">Support:</h3>
                    </div>
                    <div class="container">
                        <ul class="list-none mb-0">
                            <li class=" flex mt-2"><BiArrowToRight className="text-indigo-600 text-base me-2"/>Igchamp strives to provide our customers with industry-leading support.</li>
                            <li class=" flex mt-2"><BiArrowToRight className="text-indigo-600 text-base me-2"/>Our customer support team is 24/7 available to resolve your queries.</li>
                            <li class=" flex mt-2"><BiArrowToRight className="text-indigo-600 text-base me-2"/>You can contact the support team by filling out the “Contact Us” form. We will be happy to assist you with any questions you may have.</li>
                        </ul>
                    </div>
                    <div class="container">
                        <h3 class="mb-4 text-2xl lg:leading-normal leading-normal mt-3">Confidentiality of your information:</h3>
                    </div>
                    <div class="container">
                        <ul class="list-none mb-0">
                            <li class=" flex mt-2"><BiArrowToRight className="text-indigo-600 text-base me-2"/>Igchamp uses the information provided by you to complete your order and sends you delivery to your account.</li>
                            <li class=" flex mt-2"><BiArrowToRight className="text-indigo-600 text-base me-2"/>We also use your information to send you our upcoming promotions, coupons and information regarding any 3rd party websites that you might be interested in.</li>
                            <li class=" flex mt-2"><BiArrowToRight className="text-indigo-600 text-base me-2"/>Igchamp never sells your information to anyone.</li>
                        </ul>
                    </div>
                    <div class="container">
                        <h3 class="mb-4 text-2xl lg:leading-normal leading-normal mt-3">Payment:</h3>
                    </div>
                    <div class="container">
                        <ul class="list-none mb-0">
                            <li class=" flex mt-2"><BiArrowToRight className="text-indigo-600 text-base me-2"/>Payments on Igchamp are 256-bit SSL encrypted, secured, and using a verified payment gateway.</li>
                            <li class=" flex mt-2"><BiArrowToRight className="text-indigo-600 text-base me-2"/>While making payment on our site, make sure you have clearly understood our terms of service. We will not be accountable for any misunderstanding from your end.</li>
                            <li class=" flex mt-2"><BiArrowToRight className="text-indigo-600 text-base me-2"/>As a customer, you have authorization to use the credit card, debit card, or any other payment source used to make the purchase from us.</li>
                            <li class=" flex mt-2"><BiArrowToRight className="text-indigo-600 text-base me-2"/>While making payment you must agree to and follow all the guidelines by any transaction mode that you are using.</li>
                            <li class=" flex mt-2"><BiArrowToRight className="text-indigo-600 text-base me-2"/>Any unauthorized transaction or chargeback may lead us directly to ban your username and the IP address from the Igchamp internet website.</li>
                            <li class=" flex mt-2"><BiArrowToRight className="text-indigo-600 text-base me-2"/>Users must acknowledge that chargebacks, disputes, or payment reversals will not be treated before discussing the situation with our support team.</li>
                        </ul>
                    </div>
                    <div class="container">
                        <h3 class="mb-4 text-2xl lg:leading-normal leading-normal mt-3">Delivery:</h3>
                    </div>
                    <div class="container">
                        <ul class="list-none mb-0">
                            <li class=" flex mt-2"><BiArrowToRight className="text-indigo-600 text-base me-2"/>Late delivery by us will not be subjected to any kind of partial or complete refund.</li>
                            <li class=" flex mt-2"><BiArrowToRight className="text-indigo-600 text-base me-2"/>In some cases, due to technical error, delivery time can vary. So, you can contact our customer support if you are inconvenienced.</li>
                        </ul>
                    </div>
                    <div class="container">
                        <h3 class="mb-4 text-2xl lg:leading-normal leading-normal mt-3">Conditions:</h3>
                    </div>
                    <div class="container">
                        <ul class="list-none mb-0">
                            <li class=" flex mt-2"><BiArrowToRight className="text-indigo-600 text-base me-2"/>Igchamp or its suppliers are not responsible for any kind of damage in any case.</li>
                            <li class=" flex mt-2"><BiArrowToRight className="text-indigo-600 text-base me-2"/>Damages may arise due to loss of data, business interruption or any limitations.</li>
                            <li class=" flex mt-2"><BiArrowToRight className="text-indigo-600 text-base me-2"/>Inability to use the elements on the Igchamp website may also cause damage.</li>
                            <li class=" flex mt-2"><BiArrowToRight className="text-indigo-600 text-base me-2"/>Damages caused by any of the above reasons either in the verbal, or written notice of Igchamp or any Igchamp’s approved representatives will not be applicable to us.</li>
                        </ul>
                    </div>
                    <div class="container">
                        <h3 class="mb-4 text-2xl lg:leading-normal leading-normal mt-3">Change of Terms:</h3>
                    </div>
                    <div class="container">
                        <ul class="list-none mb-0">
                            <li class=" flex mt-2"><BiArrowToRight className="text-indigo-600 text-base me-2"/>All the information described above applies to all the services we offer at Igchamp.</li>
                            <li class=" flex mt-2"><BiArrowToRight className="text-indigo-600 text-base me-2"/>This Terms of Service is subject to change at any time. The modifications made will become effective the date these are posted on our website.</li>
                            <li class=" flex mt-2"><BiArrowToRight className="text-indigo-600 text-base me-2"/>No further notice by Igchamp is required upon your extended use of our website.</li>
                        </ul>
                    </div>
                </section>


            <Footer/>
        </>
    )
}
