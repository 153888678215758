import React, {useState} from 'react';
import {Link} from 'react-router-dom';
import {Helmet} from "react-helmet";
import Navbar from '../component/Navbar/navbar'
import Footer from '../component/Footer/footer';

import {MdKeyboardArrowRight, FaArrowRight, BsCheckCircle, FaArrowDown} from "../assets/icons/icons"
import {accordionData, consultingAbout, igFollowersFaq, pricingTableData, socialPresence} from '../data/dataTwo';
import shree_logo from "../assets/images/igchamp/our-mission.png";
import google_logo from "../assets/images/igchamp/our-vision.png";
import lenovo_logo from "../assets/images/igchamp/why-choose.png";
import circle_logo from "../assets/images/igchamp/individuals.png";

export default function About() {

    return (
        <>
            <Helmet>
                <title>About - IGChamp</title>
                <meta name="description"
                      content="Learn about Igcmap stories, mission and our vision."/>
                <meta property="og:title" content="About - igChamp"/>
                <meta property="Learn about igChamp stories, mission and our vision."
                      content="Learn about Igcmap stories, mission and our vision."/>
                <meta name="keywords" content=""/>
                <meta property="og:image" content="#"/>
                <meta property="og:url" content="/about"/>
                <link rel="canonical" href="https://igchamp.com/about"/>
            </Helmet>
            <Navbar/>

            <section className="relative table w-full py-32 lg:py-40 bg-gray-50 dark:bg-slate-800">
                <div className="container relative">
                    <div className="grid grid-cols-1 text-center mt-10">
                        <h1 className="text-3xl leading-normal font-semibold">IGChamp - About US</h1>
                        <p>At IGChamp, we specialize in empowering your social media presence with genuine engagement
                            strategies. Trust us to enhance your visibility and build a loyal following through
                            authentic interactions and increased exposure.</p>
                    </div>
                </div>
                <div className="absolute text-center z-10 bottom-5 start-0 end-0 mx-3">
                    <ul className="tracking-[0.5px] mb-0 inline-flex space-x-1">
                        <li className="inline-block uppercase text-[13px] font-bold duration-500 ease-in-out hover:text-indigo-600">
                            <Link to="/">Home</Link></li>
                        <li className="inline-block text-base mx-0.5 ltr:rotate-0 rtl:rotate-180"><MdKeyboardArrowRight
                            className="text-xl"/></li>
                        <li className="inline-block uppercase text-[13px] font-bold duration-500 ease-in-out hover:text-indigo-600">
                            <Link>IGChamp</Link></li>
                        <li className="inline-block text-base mx-0.5 ltr:rotate-0 rtl:rotate-180"><MdKeyboardArrowRight
                            className="text-xl"/></li>
                        <li className="inline-block uppercase text-[13px] font-bold duration-500 ease-in-out text-indigo-600"
                            aria-current="page">About US
                        </li>
                    </ul>
                </div>
            </section>
            <div className="relative">
                <div
                    className="shape absolute sm:-bottom-px -bottom-[2px] start-0 end-0 overflow-hidden z-1 text-white dark:text-slate-900">
                    <svg className="w-full h-auto scale-[2.0] origin-top" viewBox="0 0 2880 48" fill="none"
                         xmlns="http://www.w3.org/2000/svg">
                        <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
                    </svg>
                </div>
            </div>


            <section className="relative md:py-24 py-16 bg-gray-50 dark:bg-slate-800">
                <div className="container relative">

                    <div className="grid grid-cols-1 mt-8">
                        <div
                            className="relative after:content-[''] after:absolute after:top-0 md:after:end-0 md:after:start-0 after:w-px after:h-full md:after:m-auto after:border-s-2 after:border-dashed after:border-gray-200 dark:after:border-gray-700 ms-3 md:ms-0">
                            <div
                                className="ms-8 md:ms-0 relative after:content-[''] after:absolute after:top-[9px] after:rounded-full after:z-10 after:w-2.5 after:h-2.5 after:bg-indigo-600 md:after:mx-auto md:after:end-0 md:after:start-0 after:-start-9 before:content-[''] before:absolute md:before:mx-auto md:before:end-0 md:before:start-0 before:-start-11 before:rounded-full before:z-10 before:border-2 before:border-dashed before:border-gray-200 dark:before:border-gray-700 before:top-0 before:w-7 before:h-7 before:bg-white dark:before:bg-slate-900">
                                <div className="grid md:grid-cols-2">
                                    <div className="md:text-end md:me-8 relative">
                                        <img src={shree_logo} className=" md:ms-auto" alt=""/>
                                    </div>

                                    <div className="ltr:float-left rtl:float-right text-start ms-8 mt-6 md:mt-0">
                                        <h5 className="title mb-1 font-semibold">Our Mission</h5>
                                        <p className="mt-3 mb-0 text-slate-400">At Igchamp, our mission is to empower individuals and businesses by enhancing their social media presence through authentic and ethical growth strategies. We are dedicated to providing genuine engagement that attracts real users, ensuring your content reaches a wider audience naturally. Also, we focus on building lasting connections and fostering a loyal following by promoting organic interactions on platforms like Instagram, Facebook, YouTube, and TikTok. By prioritizing quality over quantity, we aim to create meaningful relationships and sustainable growth for your account, helping you achieve your social media goals with integrity and trust.</p>
                                    </div>
                                </div>
                            </div>

                            <div
                                className="mt-12 ms-8 md:ms-0 relative after:content-[''] after:absolute after:top-[9px] after:rounded-full after:z-10 after:w-2.5 after:h-2.5 after:bg-indigo-600 md:after:mx-auto md:after:end-0 md:after:start-0 after:-start-9 before:content-[''] before:absolute md:before:mx-auto md:before:end-0 md:before:start-0 before:-start-11 before:rounded-full before:z-10 before:border-2 before:border-dashed before:border-gray-200 dark:before:border-gray-700 before:top-0 before:w-7 before:h-7 before:bg-white dark:before:bg-slate-900">
                                <div className="grid md:grid-cols-2">
                                    <div className="text-start ms-8 relative md:order-2">
                                        <img src={google_logo} className=" md:me-auto" alt=""/>
                                    </div>

                                    <div
                                        className="ltr:float-left rtl:float-right md:text-end md:me-8 mt-6 md:mt-0 md:order-1">
                                        <h5 className="title mb-1 font-semibold">Our Vision</h5>
                                        <p className="mt-3 mb-0 text-slate-400">At Igchamp, our vision is to revolutionize how individuals and businesses grow their social media presence by championing authenticity and transparency. Therefore, we aspire to become the leading provider of social media growth services, fostering real connections and creating a supportive community for our clients. We aim to help our clients achieve unparalleled visibility and engagement by leveraging innovative strategies and staying ahead of industry trends. Besides this, we commit to empowering every user to build a credible and influential social media presence, driving meaningful interactions and lasting impact across platforms like Instagram, Facebook, YouTube, and TikTok.</p>
                                    </div>
                                </div>
                            </div>

                            <div
                                className="mt-12 ms-8 md:ms-0 relative after:content-[''] after:absolute after:top-[9px] after:rounded-full after:z-10 after:w-2.5 after:h-2.5 after:bg-indigo-600 md:after:mx-auto md:after:end-0 md:after:start-0 after:-start-9 before:content-[''] before:absolute md:before:mx-auto md:before:end-0 md:before:start-0 before:-start-11 before:rounded-full before:z-10 before:border-2 before:border-dashed before:border-gray-200 dark:before:border-gray-700 before:top-0 before:w-7 before:h-7 before:bg-white dark:before:bg-slate-900">
                                <div className="grid md:grid-cols-2">
                                    <div className="md:text-end md:me-8 relative">
                                        <img src={lenovo_logo} className="md:ms-auto" alt=""/>
                                    </div>

                                    <div className="ltr:float-left rtl:float-right text-start ms-8 mt-6 md:mt-0">
                                        <h5 className="title mb-1 font-semibold">Why Choose Igchamp?</h5>
                                        <p className="mt-3 mb-0 text-slate-400">Choosing Igchamp means choosing a partner dedicated to your social media success. We specialize in organic growth, ensuring your Instagram and TikTok presence thrives through genuine engagement and real user interactions. Our proven strategies enhance your visibility, build credibility, and foster a loyal following without relying on artificial means. With a focus on ethical practices, we prioritize quality over quantity, delivering sustainable results that stand the test of time. Our experienced team stays ahead of industry trends, providing personalized solutions tailored to your unique goals.</p>
                                    </div>
                                </div>
                            </div>

                            <div
                                className="mt-12 ms-8 md:ms-0 relative after:content-[''] after:absolute after:top-[9px] after:rounded-full after:z-10 after:w-2.5 after:h-2.5 after:bg-indigo-600 md:after:mx-auto md:after:end-0 md:after:start-0 after:-start-9 before:content-[''] before:absolute md:before:mx-auto md:before:end-0 md:before:start-0 before:-start-11 before:rounded-full before:z-10 before:border-2 before:border-dashed before:border-gray-200 dark:before:border-gray-700 before:top-0 before:w-7 before:h-7 before:bg-white dark:before:bg-slate-900">
                                <div className="grid md:grid-cols-2">
                                    <div className="text-start ms-8 relative md:order-2">
                                        <img src={circle_logo} className=" md:me-auto" alt=""/>
                                    </div>

                                    <div
                                        className="ltr:float-left rtl:float-right md:text-end md:me-8 mt-6 md:mt-0 md:order-1">
                                        <h5 className="title mb-1 font-semibold">Working with Dedicated Individuals</h5>
                                        <p className="mt-3 mb-0 text-slate-400">At Igchamp, our team is the backbone of our success. We work with dedicated social media experts, strategists, and creatives. We work together to deliver exceptional results for our clients. Each team member brings unique skills and a passion for helping you achieve your social media goals. From crafting compelling content to analyzing data and trends, our collaborative approach ensures your growth strategies are always ahead of the curve. Overall, our team ensures you can trust Igchamp to elevate your social media presence and drive meaningful connections.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


            <Footer/>
        </>
    )
}
