import React, {useState} from 'react'
import {Link} from 'react-router-dom';
import {Helmet} from "react-helmet";
import Navbar from '../component/Navbar/navbar'
import Footer from '../component/Footer/footer';

import {MdKeyboardArrowRight, FaArrowRight, BsCheckCircle, FaArrowDown} from "../assets/icons/icons";
import shree_logo from "../assets/images/igchamp/our-mission.png";
import google_logo from "../assets/images/igchamp/our-vision.png";
import lenovo_logo from "../assets/images/igchamp/why-choose.png";
import circle_logo from "../assets/images/igchamp/individuals.png";
import * as Icon from 'react-feather';
import { contactData } from '../data/data';
export default function Contact() {
    const [activeIndex, setActiveIndex] = useState(0);

    const toggleAccordion = (index) => {
        if (activeIndex === index) {
            setActiveIndex(0);
        } else {
            setActiveIndex(index);
        }
    }
    return (
        <>
            <Helmet>
                <title>Contact - IGChamp</title>
                <meta name="description"
                      content="Learn about Igcmap stories, mission and our vision."/>
                <meta property="og:title" content="Contact - igChamp"/>
                <meta property="Learn about igChamp stories, mission and our vision."
                      content="Learn about Igcmap stories, mission and our vision."/>
                <meta name="keywords" content=""/>
                <meta property="og:image" content="#"/>
                <meta property="og:url" content="/contact"/>
                <link rel="canonical" href="https://igchamp.com/contact"/>
            </Helmet>
            <Navbar/>

            <section className="relative table w-full py-32 lg:py-40 bg-gray-50 dark:bg-slate-800">
                <div className="container relative">
                    <div className="grid grid-cols-1 text-center mt-10">
                        <h1 className="text-3xl leading-normal font-semibold">IGChamp - Contact US</h1>
                        <p>If you have any query feel free to contact us.</p>
                    </div>
                </div>
                <div className="absolute text-center z-10 bottom-5 start-0 end-0 mx-3">
                    <ul className="tracking-[0.5px] mb-0 inline-flex space-x-1">
                        <li className="inline-block uppercase text-[13px] font-bold duration-500 ease-in-out hover:text-indigo-600">
                            <Link to="/">Home</Link></li>
                        <li className="inline-block text-base mx-0.5 ltr:rotate-0 rtl:rotate-180"><MdKeyboardArrowRight
                            className="text-xl"/></li>
                        <li className="inline-block uppercase text-[13px] font-bold duration-500 ease-in-out hover:text-indigo-600">
                            <Link>Igchamp</Link></li>
                        <li className="inline-block text-base mx-0.5 ltr:rotate-0 rtl:rotate-180"><MdKeyboardArrowRight
                            className="text-xl"/></li>
                        <li className="inline-block uppercase text-[13px] font-bold duration-500 ease-in-out text-indigo-600"
                            aria-current="page">Contact US
                        </li>
                    </ul>
                </div>
            </section>
            <div className="relative">
                <div
                    className="shape absolute sm:-bottom-px -bottom-[2px] start-0 end-0 overflow-hidden z-1 text-white dark:text-slate-900">
                    <svg className="w-full h-auto scale-[2.0] origin-top" viewBox="0 0 2880 48" fill="none"
                         xmlns="http://www.w3.org/2000/svg">
                        <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
                    </svg>
                </div>
            </div>


            <section className="relative md:py-24 py-16">
                <div className="container relative">
                    <div className="grid grid-cols-1 lg:grid-cols-2 md:grid-cols-2 gap-[30px]">
                        {contactData.map((item, index) => {
                            let Icons = item.icon
                            return (
                                <div key={index} className="text-center px-6 mt-6">
                                    <div
                                        className="w-20 h-20 bg-indigo-600/5 text-indigo-600 rounded-xl text-3xl flex align-middle justify-center items-center shadow-sm dark:shadow-gray-800 mx-auto">
                                        <Icons className="w-7 h-7"/>
                                    </div>

                                    <div className="content mt-7">
                                        <h5 className="title h5 text-xl font-medium">{item.title}</h5>
                                        <p className="text-slate-400 mt-3">{item.desc}</p>

                                        <div className="mt-5">
                                            <Link to="tel:+17868274921"
                                                  className="relative inline-block font-semibold tracking-wide align-middle text-base text-center border-none after:content-[''] after:absolute after:h-px after:w-0 hover:after:w-full after:end-0 hover:after:end-auto after:bottom-0 after:start-0 after:duration-500 text-indigo-600 hover:text-indigo-600 after:bg-indigo-600 duration-500">{item.contact}</Link>
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>

            </section>

            <Footer/>
        </>
    )
}
