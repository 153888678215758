

import client1 from '../assets/images/client/01.jpg';
import client2 from '../assets/images/client/02.jpg';
import client3 from '../assets/images/client/03.jpg';
import client4 from '../assets/images/client/04.jpg';
import client5 from '../assets/images/client/05.jpg';
import client6 from '../assets/images/client/06.jpg';
import {FiPhone} from "react-icons/fi";
import {RiMapPinLine} from "react-icons/ri";
import {FaRegEnvelope} from "react-icons/fa";



export const footerLinks = [
    {
        liClass: '',
        route: '/buy-instagram-followers',
        title: 'Buy Instagram Followers',

    },
    {
        liClass: 'mt-[10px]',
        route: '/buy-instagram-likes',
        title: 'Buy Instagram Likes',

    },
    {
        route: '/buy-instagram-views',
        title: 'Buy Instagram Views',
        liClass: 'mt-[10px]',
    },
    {
        route: '/buy-tiktok-likes',
        title: 'Buy Tiktok Likes',
        liClass: 'mt-[10px]',
    },
    {
        route: '/buy-tiktok-followers',
        title: 'Buy Tiktok Followers',
        liClass: 'mt-[10px]',
    },
    {
        route: '/buy-tiktok-views',
        title: 'buy Tiktok Views',
        liClass: 'mt-[10px]',
    }
];
export const footerCompany = [
    {
        liClass: '',
        route: '/about',
        title: 'About us',
    },
    {
        liClass: 'mt-[10px]',
        route: '/term-of-service',
        title: 'Term of Service',

    },
    {
        route: '/privacy-policy',
        title: 'Privacy Policy',
        liClass: 'mt-[10px]',

    },
    {
        route: '/blog',
        title: 'Blog',
        liClass: 'mt-[10px]',

    },
    {
        route: '/contact',
        title: 'Contact',
        liClass: 'mt-[10px]',

    }
]


export const feedback =[
    {
        description: '"IG Champ has been a game-changer for my Instagram presence. I saw an immediate boost in followers, and the engagement on my profile has skyrocketed! It’s clear that IG Champ delivers real, high-quality followers. My business has never looked better on social media."',
        image: client2,
        name: 'Sarah, Sydney, AU',
        role: 'Customer'
    },
    {
        description: `"As a content creator, I always need high engagement for my posts. IG Champ’s Instagram Likes service gave me the visibility I needed to reach a larger audience. The likes looked natural and helped my posts go viral. Highly recommend!"`,
        image: client1,
        name: 'James, Manchester, UK',
        role: 'Customer'
    },
    {
        description: '"Thanks to IG Champ, my video views on Instagram shot through the roof! This service gave me the social proof I needed to establish myself as a credible brand. I’ve noticed a significant increase in new followers and interactions since using it."',
        image: client3,
        name: 'Emily, Vancouver, CA',
        role: 'Customer'
    },
    {
        description: '"IG Champ’s TikTok Followers service was exactly what I needed to gain traction on my new TikTok account. I gained major amount of followers in just a few days, and the best part is, they engage with my content. It’s the real deal!"',
        image: client4,
        name: 'Oliver, NY, USA',
        role: 'Customer'
    },
    {
        description: '"After using IG Champ’s service to buy TikTok Likes, I saw a huge difference in my video’s popularity. The likes helped boost my content, getting it in front of more viewers and even landing it on the For You page. I couldn’t be happier with the results!"',
        image: client5,
        name: 'Charlotte, Milan, IT',
        role: 'Customer'
    },
    {
        description: '"My TikTok videos were struggling to get noticed until I found IG Champ’s TikTok Views service. Now my views have multiplied, and I’m gaining followers every day. This service has truly given my profile the boost it needed!"',
        image: client6,
        name: 'Daniel, Munich, Germany',
        role: 'Customer'
    }
]
export const contactData = [
    {
        icon: FiPhone,
        title:"Phone",
        desc:"WhatsApp also available on this number",
        contact:"+17868274921"
    },
    {
        icon:FaRegEnvelope,
        title:"Email",
        desc:"24/7",
        contact:"hello@igchamp.com"
    }
]


