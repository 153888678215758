import React, {useEffect, useState} from 'react';
import {Link,useLocation, useParams} from 'react-router-dom';
import {Helmet} from "react-helmet";
import Navbar from '../component/Navbar/navbar'
import Footer from '../component/Footer/footer';

import Globals from '../Globals';

export default function BlogPost() {
    const {slug} = useParams()
    var WPAPI = require( 'wpapi' );
    const [posts,setPosts] = useState([]);
    useEffect(() => {
        async function loadPosts() {

            var wp = new WPAPI({
                endpoint: Globals.WP_API_URL,
            });
            wp.posts().get(function( err, posts ) {

                let post=posts[Object.keys(posts).find((post, i) => {
                    return posts[post].slug === slug;
                })] || {};
                setPosts(post);
            });
        }

        loadPosts();
    }, [])
    let css = "h2{font-size:24px;padding-top: 20px;adding-bottom: 20px;}h3{font-size:18px;padding-top: 10px;adding-bottom: 10px;}"
    return (
        <>
            <Helmet>
                <title>{posts.title ? posts.title.rendered : ""} - IGCChamp</title>
                <meta property="og:title" content={`${posts.title ?? ""} - IGChamp`}/>
                <meta property="og:image" content={posts.featured_image_src}/>
                <meta property="og:url" content={`https://igchamp.com/blog/${posts.slug}`}/>
                <link rel="canonical" href={`https://igchamp.com/blog/${posts.slug}`}/>
                <style>
                    {css}
                </style>
            </Helmet>
            <Navbar/>

            <section className="relative table w-full py-32 lg:py-40 bg-gray-50 dark:bg-slate-800">
                <div className="container relative">
                    <div className="grid grid-cols-1 mt-10">
                        {posts.id &&
                            <div>
                                <h1 className="text-5xl leading-normal font-semibold text-center p-6 text-red-600" > {posts.title.rendered}</h1>
                                <img src={posts.featured_image_src}/>
                                <div className="pt-6" dangerouslySetInnerHTML={{__html: posts.content.rendered}}/>
                            </div>
                        }

                    </div>
                </div>

            </section>





            <Footer/>
        </>
    )
}
