import React, {useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import {Helmet} from "react-helmet";

import '../../../node_modules/tiny-slider/dist/tiny-slider.css'

import Navbar from '../../component/Navbar/navbar';
import Footer from '../../component/Footer/footer';

import GetInTuch from '../../component/getInTuch';

import {
    FaUser,
    FaThumbsUp,
    BsCheckCircle,
    FaEye,
    MdKeyboardArrowRight
} from '../../assets/icons/icons'

import classic02 from "../../assets/images/igchamp/instagram-growth-ig-champ.webp";
import classic03 from "../../assets/images/igchamp/boost-instagram-with-ig-champ.webp";
import classic04 from "../../assets/images/client/01.jpg";
import classic05 from "../../assets/images/client/05.jpg";
import classic06 from "../../assets/images/client/02.jpg";
import classic07 from "../../assets/images/client/04.jpg";
import classic08 from "../../assets/images/client/03.jpg";
import classic09 from "../../assets/images/client/06.jpg";


import {
    categories,
    constructionAbout, consultingAbout,
    pricingTableData
} from "../../data/dataTwo";

import UserFeedBack2 from "../../component/userFeedBack2";

export default function Index() {
    useEffect(() => {
        document.documentElement.setAttribute("dir", "ltr");
        document.documentElement.classList.add('light');
    }, []);
    const [pricingTable, setpricingTable] = useState(pricingTableData.filter((element) => {
        return element.cat === 10;
    }))

    const [isOpenTab, setisOpenTab] = useState(1);
    const [isOpenSubCat, setisOpenSubCat] = useState(10);
    let selectedCategory = categories.find((element) => {
        return element.id === 1;
    });
    const [subcate, setSubcate] = useState(selectedCategory.sub);
    useEffect(() => {
        setpricingTable(
            pricingTableData.filter((element) => {
                return element.cat === isOpenSubCat;
            })
        );
    }, [isOpenSubCat]);
    const handleTabClick = (id) => {
        setisOpenTab(id);
        setisOpenSubCat(id * 10);
        selectedCategory = categories.find((element, index) => {
            return element.id === id;
        })
        setSubcate(selectedCategory.sub)
    };
    const handleSubCat = (id) => {
        setisOpenSubCat(id);

    };

    const ldJson =
        {
            "@context": "https://schema.org/",
            "@type": "BreadcrumbList",
            "itemListElement": [
                {
                    "@type": "ListItem",
                    "position": 1,
                    "name": "Home",
                    "item": "https://igchamp.com"
                }
            ]
        };
    let companyJson =
        {
            "@context": "https://schema.org",
            "@type": "Organization",
            "name": "IGChamp",
            "alternateName": "IG Champ",
            "url": "https://igchamp.com",
            "email": "hello@igchamp.com",
            "logo": "https://igchamp.com/ig-champ-logo.png",
            "contactPoint": {
                "@type": "ContactPoint",
                "telephone": "+17868274921",
                "contactType": "technical support",
                "availableLanguage": "en"
            },
            "sameAs": "https://www.linkedin.com/company/igchamp"
        };
    let reviewsJson =
        {
            "@context": "https://schema.org/",
            "@type": "WebSite",
            "name": "IGChamp",
            "url": "https://igchamp.com",
            "inLanguage": "en-us",
            "description": "Checkout IGChamp Instagram growth services with customized packages. Get real followers, likes and views at cheap prices with Instant delivery"
        };
    return (
        <div>
            <Helmet>
                <title>IGChamp - Buy instant followers, likes and views</title>
                <link rel="canonical" data-react-helmet="true" hrefLang="en-us" href="https://igchamp.com"/>
                <meta name="keywords" content="IG Champ, IGChamp, Buy instagram Followers, Buy Followers"/>
                <meta name="description"
                      content="Buy Instagram followers, likes and views Instantly and easily from IGChamp. Voted as #1 social media services provider in the market."/>
                <meta property="og:title"
                      content="IGChamp Instagram Growth services - Buy instant followers, likes and views"/>
                <meta property="og:description"
                      content="Buy Instagram Likes, followers and views Instantly and easily from IGChamp. Voted #1 social media services provider in the market."/>
                <meta property="og:image" content={classic02}/>
                <meta property="og:url" content="https://igchamp.com"/>
                <script type="application/ld+json">
                    {JSON.stringify(ldJson)}
                </script>
                <script type="application/ld+json">
                    {JSON.stringify(companyJson)}
                </script>
                <script type="application/ld+json">
                    {JSON.stringify(reviewsJson)}
                </script>
            </Helmet>
            <Navbar/>
            <section className="relative overflow-hidden md:pt-24 pt-28 bg-gray-50 dark:bg-slate-800">
                <div className="container relative">
                    <div className="grid md:grid-cols-2 grid-cols-1 items-center mt-8 gap-[30px] relative">

                        <div className="md:me-6">
                            <ul className="list-none mb-0 text-amber-400 text-xl space-x-1">
                                <li className="inline"><i className="mdi mdi-star"></i></li>
                                <li className="inline"><i className="mdi mdi-star"></i></li>
                                <li className="inline"><i className="mdi mdi-star"></i></li>
                                <li className="inline"><i className="mdi mdi-star"></i></li>
                                <li className="inline"><i className="mdi mdi-star"></i></li>
                            </ul>
                            <h1 className="font-bold lg:leading-normal leading-normal text-3xl lg:text-4xl mb-5 text-black dark:text-white">
                                Grow Your Instagram Presence with <span
                                className="after:absolute after:end-0 after:start-0 after:bottom-1 after:-rotate-3 after:h-2 after:w-auto after:rounded-md after:bg-indigo-600/20 relative text-indigo-600">IGChamp</span>
                            </h1>
                            <p className="text-slate-400 text-lg max-w-xl">Are you ready to skyrocket your Instagram
                                influence? At IGChamp, we specialize in providing superior Instagram growth services to
                                enhance your Instagram growth, engagement, and overall success.
                            </p>

                            <div className="mt-6">
                                <Link to="/buy-instagram-followers"
                                      className="py-2 px-5 inline-flex items-center font-semibold tracking-wide border align-middle duration-500 text-base text-center bg-indigo-600 hover:bg-indigo-700 border-indigo-600 hover:border-indigo-700 text-white rounded-md me-2 mt-2"><FaUser
                                    className="me-2 text-sm "/> Buy Followers</Link>
                                <Link to="/buy-instagram-likes"
                                      className="py-2 px-5 inline-flex items-center font-semibold tracking-wide border align-middle duration-500 text-base text-center bg-indigo-600 hover:bg-indigo-700 border-indigo-600 hover:border-indigo-700 text-white rounded-md me-2 mt-2"><FaThumbsUp
                                    className="me-2 text-sm "/> Buy Likes</Link>
                                <Link to="/buy-instagram-views"
                                      className="py-2 px-5 inline-flex items-center font-semibold tracking-wide border align-middle duration-500 text-base text-center bg-indigo-600 hover:bg-indigo-700 border-indigo-600 hover:border-indigo-700 text-white rounded-md me-2 mt-2"><FaThumbsUp
                                    className="me-2 text-sm "/> Buy Views</Link>
                            </div>
                        </div>

                        <div className="relative">
                            <img src={classic02} className="mx-auto " alt="Buy Instagram followers"/>
                            <div
                                className="overflow-hidden after:content-[''] after:absolute after:h-10 after:w-10 after:bg-indigo-600/20 after:top-0 after:start-0 after:-z-1 after:rounded-lg after:animate-[spin_10s_linear_infinite]"></div>


                        </div>

                        <div className="relative animate-[spin_30s_linear_infinite] -z-1">
                <span
                    className="after:absolute after:start-0 after:bottom-1/2 after:translate-y-1/2 after:h-2 after:w-8 after:rounded-md after:bg-indigo-600/20 relative after:z-10"></span>
                            <span
                                className="after:absolute after:start-0 after:bottom-1/2 after:translate-y-1/2 after:rotate-90 after:h-2 after:w-8 after:rounded-md after:bg-indigo-600/20 relative after:z-10"></span>
                        </div>
                    </div>
                </div>

            </section>

            <section>
                <div className="container relative pt-8">
                    <div className="grid grid-cols-1 pb-8 text-center"><h3
                        className="mb-4 md:text-3xl md:leading-normal text-2xl leading-normal font-semibold"><b
                        className="text-red-600"> IGChamp's</b> Services!</h3>
                    </div>
                    <div className="grid md:grid-cols-3 grid-cols-1 mt-8 gap-[30px]">
                        <div
                            className="group border-b-[3px] dark:border-gray-700 p-6 py-8 hover:border-indigo-600 dark:hover:border-indigo-600 transition-all duration-500 ease-in-out hover:scale-105 relative overflow-hidden shadow dark:shadow-gray-800 rounded-md bg-gray-50 dark:bg-slate-800 hover:bg-white dark:hover:bg-slate-900 h-fit">
                            <div
                                className="size-16 bg-indigo-600/5 text-indigo-600 rounded-lg text-2xl flex align-middle justify-center items-center shadow-sm dark:shadow-gray-800 mx-auto">
                            </div>
                            <div className="content mt-7"><h3
                                className="title h5 text-lg font-medium hover:text-indigo-600"
                            >Buy Instagram Followers</h3>
                                <p className="text-slate-400 mt-4">You can buy real Instagram  followers safely from us as we
                                    will not impact the brand image or reputation. The genuine and safe techniques
                                    ensure that we do not violate any term and condition of Instagram. So, <Link to="/buy-instagram-followers"><b
                                        className="text-red-600">buy
                                        Instagram followers</b></Link> and build a cohesive community and real fan base at the
                                    lowest rate in the market.</p>
                                <div className="mt-5">
                                    <Link to="/buy-instagram-followers"
                                          className="py-2 px-5 inline-flex items-center font-semibold tracking-wide border align-middle duration-500 text-base text-center bg-indigo-600 hover:bg-indigo-700 border-indigo-600 hover:border-indigo-700 text-white rounded-md me-2 mt-2"><FaUser
                                        className="me-2 text-sm "/> Buy IG Followers</Link></div>
                            </div>
                        </div>
                        <div
                            className="group border-b-[3px] dark:border-gray-700 p-6 py-8 hover:border-indigo-600 dark:hover:border-indigo-600 transition-all duration-500 ease-in-out hover:scale-105 relative overflow-hidden shadow dark:shadow-gray-800 rounded-md bg-gray-50 dark:bg-slate-800 hover:bg-white dark:hover:bg-slate-900 h-fit">
                            <div
                                className="size-16 bg-indigo-600/5 text-indigo-600 rounded-lg text-2xl flex align-middle justify-center items-center shadow-sm dark:shadow-gray-800 mx-auto">

                            </div>
                            <div className="content mt-7"><h3
                                className="title h5 text-lg font-medium hover:text-indigo-600">Buy Instagram Likes</h3>
                                <p className="text-slate-400 mt-3"><Link to='/buy-instagram-likes'><b
                                    className="text-red-600">Buy Instagram likes</b></Link> to get hearts on the posts.
                                    Instagram always prioritizes engagement. Hence more likes are synonymous with
                                    improved engagement. When there are more hearts in your posts, Instagram will be
                                    more likely to promote you organically. Choose your favorite package and start your
                                    IG journey Today!</p>
                                <div className="mt-5">
                                    <Link to="/buy-instagram-likes"
                                          className="py-2 px-5 inline-flex items-center font-semibold tracking-wide border align-middle duration-500 text-base text-center bg-indigo-600 hover:bg-indigo-700 border-indigo-600 hover:border-indigo-700 text-white rounded-md me-2 mt-2"><FaThumbsUp
                                        className="me-2 text-sm "/> Buy IG Likes</Link></div>
                            </div>
                        </div>
                        <div
                            className="group border-b-[3px] dark:border-gray-700 p-6 py-8 hover:border-indigo-600 dark:hover:border-indigo-600 transition-all duration-500 ease-in-out hover:scale-105 relative overflow-hidden shadow dark:shadow-gray-800 rounded-md bg-gray-50 dark:bg-slate-800 hover:bg-white dark:hover:bg-slate-900 h-fit">
                            <div
                                className="size-16 bg-indigo-600/5 text-indigo-600 rounded-lg text-2xl flex align-middle justify-center items-center shadow-sm dark:shadow-gray-800 mx-auto">

                            </div>
                            <div className="content mt-7"><h3
                                className="title h5 text-lg font-medium hover:text-indigo-600">Buy Instagram Views</h3><p
                                className="text-slate-400 mt-3"><Link to="/buy-instagram-views"><b
                                className="text-red-600"> Buy Instagram views</b></Link> and see how magically it
                                wonders for your business. It helps you to have increased visibility, exposure, and
                                potential customers. It makes you to have more profit or high return on investment at
                                the end of the day. Start boosting your videos reach today and go viral.</p>
                                <div className="mt-5"><Link to="/buy-instagram-views"
                                                            className="py-2 px-5 inline-flex items-center font-semibold tracking-wide border align-middle duration-500 text-base text-center bg-indigo-600 hover:bg-indigo-700 border-indigo-600 hover:border-indigo-700 text-white rounded-md me-2 mt-2"><FaEye
                                    className="me-2 text-sm "/> Buy IG Views</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="grid md:grid-cols-3 grid-cols-1 mt-8 gap-[30px] mb-6">
                        <div
                            className="group border-b-[3px] dark:border-gray-700 p-6 py-8 hover:border-indigo-600 dark:hover:border-indigo-600 transition-all duration-500 ease-in-out hover:scale-105 relative overflow-hidden shadow dark:shadow-gray-800 rounded-md bg-gray-50 dark:bg-slate-800 hover:bg-white dark:hover:bg-slate-900 h-fit">
                            <div
                                className="size-16 bg-indigo-600/5 text-indigo-600 rounded-lg text-2xl flex align-middle justify-center items-center shadow-sm dark:shadow-gray-800 mx-auto">
                            </div>
                            <div className="content mt-7"><h3
                                className="title h5 text-lg font-medium hover:text-indigo-600">Buy TikTok Followers</h3>
                                <p className="text-slate-400 mt-3">Gain more exposure for your new venture with <Link
                                    to="/buy-tiktok-followers"><b className="text-red-600">real TikTok
                                    followers</b></Link> . We offer the best quality followers with unparalleled
                                    after-sale services. Buy followers today and watch your TikTok handle gaining more
                                    visibility, exposure, and brand recognition.</p>
                                <div className="mt-5 ">
                                    <Link to="/buy-titok-followers"
                                          className="py-2 px-5 inline-flex items-center font-semibold tracking-wide border align-middle duration-500 text-base text-center bg-indigo-600 hover:bg-indigo-700 border-indigo-600 hover:border-indigo-700 text-white rounded-md me-2 mt-2"><FaUser
                                        className="me-2 text-sm "/> TikTok Followers</Link></div>
                            </div>
                        </div>
                        <div
                            className="group border-b-[3px] dark:border-gray-700 p-6 py-8 hover:border-indigo-600 dark:hover:border-indigo-600 transition-all duration-500 ease-in-out hover:scale-105 relative overflow-hidden shadow dark:shadow-gray-800 rounded-md bg-gray-50 dark:bg-slate-800 hover:bg-white dark:hover:bg-slate-900 h-fit">
                            <div
                                className="size-16 bg-indigo-600/5 text-indigo-600 rounded-lg text-2xl flex align-middle justify-center items-center shadow-sm dark:shadow-gray-800 mx-auto">

                            </div>
                            <div className="content mt-7"><h3
                                className="title h5 text-lg font-medium hover:text-indigo-600">Buy Tiktok Likes</h3>
                                <p className="text-slate-400 mt-3">Do you have a high content but not significant likes!
                                    The chances are more that people will skip the content before even looking at it.
                                    Since a number of likes on a post reflect the popularity and authenticity. With
                                    IGChamp, you can <Link to="/buy-tiktok-likes"><b className="text-red-600">buy real
                                        TikTok likes</b></Link> with instant delivery.</p>
                                <div className="mt-5">
                                    <Link to="/buy-tiktok-likes"
                                          className="py-2 px-5 inline-flex items-center font-semibold tracking-wide border align-middle duration-500 text-base text-center bg-indigo-600 hover:bg-indigo-700 border-indigo-600 hover:border-indigo-700 text-white rounded-md me-2 mt-2"><FaThumbsUp
                                        className="me-2 text-sm "/> TikTok Likes</Link></div>
                            </div>
                        </div>
                        <div
                            className="group border-b-[3px] dark:border-gray-700 p-6 py-8 hover:border-indigo-600 dark:hover:border-indigo-600 transition-all duration-500 ease-in-out hover:scale-105 relative overflow-hidden shadow dark:shadow-gray-800 rounded-md bg-gray-50 dark:bg-slate-800 hover:bg-white dark:hover:bg-slate-900 h-fit">
                            <div
                                className="size-16 bg-indigo-600/5 text-indigo-600 rounded-lg text-2xl flex align-middle justify-center items-center shadow-sm dark:shadow-gray-800 mx-auto">

                            </div>
                            <div className="content mt-7"><h3
                                className="title h5 text-lg font-medium hover:text-indigo-600">Buy TikTok Views</h3>
                                <p className="text-slate-400 mt-3">Views are a fundamental part of your successful
                                    TikTok journey. <Link to="/buy-tiktok-views"><b className="text-red-600">Buy TikTok
                                        views</b></Link> to get more popularity and exposure from the target audience.
                                    We always got your back 24/7 as we understand there is nothing worse than a poor
                                    customer support. </p>
                                <div className="mt-5"><Link to="/buy-titok-views"
                                                            className="py-2 px-5 inline-flex items-center font-semibold tracking-wide border align-middle duration-500 text-base text-center bg-indigo-600 hover:bg-indigo-700 border-indigo-600 hover:border-indigo-700 text-white rounded-md me-2 mt-2"><FaEye
                                    className="me-2 text-sm "/> TikTok Views</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="w-full table relative bg-gray-50 dark:bg-slate-800 pt-8 pb-16">
                <div className="container p-6 bg-white dark:bg-slate-900 rounded-md shadow dark:shadow-gray-800">
                    <div className="grid grid-cols-1 pb-8 text-center">
                        <h2 className="mb-4 md:text-3xl md:leading-normal text-2xl leading-normal font-semibold">Buy
                            real Instagram Followers from the #1 Social Media Agency</h2>
                        <p className="text-slate-400">Digital advertisement and promotion is no luxury but a necessity
                            to compete in a highly crowded market. If you are one planning for the digital strategy, you
                            must have been hearing about Instagram – the top social platform to get more exposure for a
                            newly established brand. We offer superior Instagram services with automatic and instant
                            delivery. We understand how difficult it becomes for a startup to build a fanbase of a few
                            hundred thousand followers. <b>IGChamp</b> is here to help you <b>buy
                                Instagram followers</b> to make the business boom.</p>
                        <p className="text-slate-400">Our services are unparalleled, and we offer the top-notch social media services on the most
                            competitive rate. More social media followers will eventually lead your profile to get
                            better trust and credibility and ultimately, more organic followers and engagement.
                        </p>
                    </div>
                </div>
            </section>
            <section
                className="w-full table relative bg-gray-50 dark:bg-slate-800 pb-16">

                <div className="container relative mt-16">
                    <div className="grid grid-cols-1 pb-8 text-center">
                        <h2 className="mb-4 md:text-3xl md:leading-normal text-2xl leading-normal font-semibold">Why
                            Choose IGChamp?</h2>
                        <p className="text-slate-400">We Feel happy to be called the leading player as IGChamp is the
                            best platform to <b>buy
                                Instagram followers</b>. We deploy 100% original and authentic method to grow
                            your audience and provide you with real followers, likes, and views.</p>
                    </div>
                    <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-[24px]">
                        {consultingAbout.map((item, index) => {
                            let Icons = item.icon
                            return (
                                <div key={index}
                                     className="group border-b-[3px] dark:border-gray-700 p-6 py-8 hover:border-indigo-600 dark:hover:border-indigo-600 transition-all duration-500 ease-in-out hover:scale-105 relative overflow-hidden shadow dark:shadow-gray-800 rounded-md bg-gray-50 dark:bg-slate-800 hover:bg-white dark:hover:bg-slate-900 h-fit">
                                    <Icons className="h-10 w-10 stroke-1 text-indigo-600"/>

                                    <div className="content mt-6">
                                        <h4
                                            className="title h5 text-xl font-semibold hover:text-indigo-600">{item.title}</h4>
                                        <p className="text-slate-400 mt-4">{item.desc}</p>

                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </section>

            <section className="container relative md:mt-24 mt-16 mb-16">
                <div className="grid grid-cols-1 justify-center">
                    <div className="">
                        <div
                            className="relative bg-white dark:bg-slate-900 shadow dark:shadow-gray-800 rounded-md overflow-hidden">
                            <div className="grid lg:grid-cols-12 grid-cols-1">
                                <div className="lg:col-span-4 order-1 lg:order-2 bg-indigo-600">
                                    <div className="p-[30px] lg:text-start text-center">
                                        <span className="text-xl text-white/75">Order Placement</span>
                                        <h3 className="text-2xl font-semibold text-white my-3">How to Buy from
                                            IGChamp?</h3>
                                        <p className="text-white/75 max-w-xl mx-auto">IGChamp offers a number of
                                            Instagram services, including followers, likes, and views for users
                                            globally. If you’re interested in ordering any of these services from our
                                            website, here are the basic steps you need to take:</p>
                                        {/*<div className="mt-6">
                          <Link to="#"
                                className="py-2 px-5 inline-block font-semibold tracking-wide border align-middle transition duration-500 ease-in-out text-base text-center bg-green-600 hover:bg-green-700 border-green-600 hover:border-green-700 text-white rounded-md">About
                            us</Link>
                        </div>*/}
                                    </div>
                                </div>

                                <div className="lg:col-span-8 order-2 lg:order-1">
                                    <div className="grid md:grid-cols-2 grid-cols-1 p-[30px] gap-[30px]">
                                        {constructionAbout.map((item, index) => {
                                            let Icons = item.icon
                                            return (
                                                <div className="group flex transition-all duration-500 ease-in-out"
                                                     key={index}>
                                                    <div
                                                        className="flex align-middle justify-center items-center w-14 h-14 mt-1 bg-indigo-600/5 group-hover:bg-indigo-600 group-hover:text-white text-indigo-600 rounded-full text-2xl shadow-sm dark:shadow-gray-800 transition-all duration-500 ease-in-out">
                                                        <Icons className='w-8 h-8'/>
                                                    </div>
                                                    <div className="flex-1 ms-4">
                                                        <h4 className="mb-0 text-lg font-medium">{item.title}</h4>
                                                        <p className="text-slate-400 mt-3">{item.desc}</p>
                                                    </div>
                                                </div>
                                            )
                                        })}

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </section>
            <section className=" relative overflow-hidden pb-32 bg-gray-50 dark:bg-slate-800">

                <div className="container grid md:grid-cols-2 grid-cols-1 items-center mt-16 gap-[30px]">
                    <div className="relative wow animate__animated animate__fadeInLeft" data-wow-delay=".3s">
                        <img src={classic03} className="mx-auto" alt="IGChamp - Boost Your Instagram Today"/>
                        <div
                            className="overflow-hidden absolute lg:h-[400px] h-[320px] lg:w-[400px] w-[320px] bg-indigo-600/5 bottom-0 start-0 rotate-45 -z-1 rounded-3xl"></div>
                    </div>

                    <div className="lg:ms-8 wow animate__animated animate__fadeInRight" data-wow-delay=".3s">
                        <h3 className="mb-4 text-2xl leading-normal font-medium">Start Boosting Your Instagram Today
                            with IGChamp</h3>
                        <p className="text-slate-400">It's time to start boosting your Instagram presence today and see
                            real, tangible results. Our comprehensive range of services ensures that every aspect of
                            your Instagram account is optimized for success. Our team of experts uses proven,
                            data-driven techniques to attract genuine followers, increase your engagement rates, and
                            build a loyal audience around your Instagram presence.</p>
                        <ul className="list-none text-slate-400 mt-4">
                            <li className="mb-1 flex items-center"><BsCheckCircle
                                className="text-indigo-600 text-base me-2"/> Real Followers
                            </li>
                            <li className="mb-1 flex items-center"><BsCheckCircle
                                className="text-indigo-600 text-base me-2"/> Affordable Prices
                            </li>
                            <li className="mb-1 flex items-center"><BsCheckCircle
                                className="text-indigo-600 text-base me-2"/> Organic Growth
                            </li>
                            <li className="mb-1 flex items-center"><BsCheckCircle
                                className="text-indigo-600 text-base me-2"/> 24/7 Customer Support
                            </li>
                        </ul>

                        <div className="mt-4">
                            <Link to="/buy-instagram-followers"
                                  className="relative inline-flex items-center font-semibold tracking-wide align-middle text-base text-center border-none after:content-[''] after:absolute after:h-px after:w-0 hover:after:w-full after:end-0 hover:after:end-auto after:bottom-0 after:start-0 after:duration-500 text-indigo-600 hover:text-indigo-600 after:bg-indigo-600 duration-500">
                                Click here to boost you Instagram
                                <MdKeyboardArrowRight className="text-xl ms-1"/></Link>
                        </div>
                    </div>
                </div>
            </section>
            <section className="container relative md:py-8 py-4">


                <div className="container relative md:mt-24 mt-16">
                    <div className="grid grid-cols-1 pb-8 text-center">
                        <h3 className="mb-4 md:text-3xl md:leading-normal text-2xl leading-normal font-semibold">What
                            Our Clients Are Saying About IGChamp</h3>
                    </div>
                </div>
                <div className="grid lg:grid-cols-3 sm:grid-cols-2 grid-cols-1 mt-8 gap-[30px]">
                    <ul className="space-y-8">
                        <li className="rounded-lg shadow-lg dark:shadow-gray-800 p-6">
                            <div className="flex items-center pb-6 border-b border-gray-100 dark:border-gray-800">
                                <img src={classic04}
                                     className="size-16 rounded-full shadow dark:shadow-gray-800" alt=""/>

                                <div className="ps-4">
                                    <span className="text-lg hover:text-indigo-600 duration-500 ease-in-out">Thomas
                                        Jordon</span>
                                </div>
                            </div>

                            <div className="mt-6">
                                <p className="text-slate-400">IG Champ has been a game-changer for my Instagram
                                    presence. I saw an immediate boost in followers, and the engagement on my profile
                                    has skyrocketed! It’s clear that IG Champ delivers real, high-quality followers. My
                                    business has never looked better on social media.</p>
                                <ul className="list-none mb-0 text-amber-400 mt-2">
                                    <li className="inline"><i className="mdi mdi-star"></i></li>
                                    <li className="inline"><i className="mdi mdi-star"></i></li>
                                    <li className="inline"><i className="mdi mdi-star"></i></li>
                                    <li className="inline"><i className="mdi mdi-star"></i></li>
                                    <li className="inline"><i className="mdi mdi-star"></i></li>
                                </ul>
                            </div>
                        </li>

                        <li className="rounded-lg shadow-lg dark:shadow-gray-800 p-6">
                            <div className="flex items-center pb-6 border-b border-gray-100 dark:border-gray-800">
                                <img src={classic05}
                                     className="size-16 rounded-full shadow dark:shadow-gray-800" alt=""/>

                                <div className="ps-4">
                                    <span className="text-lg hover:text-indigo-600 duration-500 ease-in-out">Alice
                                        Roberto</span>
                                </div>
                            </div>

                            <div className="mt-6">
                                <p className="text-slate-400">As a content creator, I always need high engagement for my
                                    posts. IG Champ’s Instagram Likes service gave me the visibility I needed to reach a
                                    larger audience. The likes looked natural and helped my posts go viral. Highly
                                    recommend!</p>
                                <ul className="list-none mb-0 text-amber-400 mt-2">
                                    <li className="inline"><i className="mdi mdi-star"></i></li>
                                    <li className="inline"><i className="mdi mdi-star"></i></li>
                                    <li className="inline"><i className="mdi mdi-star"></i></li>
                                    <li className="inline"><i className="mdi mdi-star"></i></li>
                                    <li className="inline"><i className="mdi mdi-star"></i></li>
                                </ul>
                            </div>
                        </li>
                    </ul>

                    <ul className="space-y-8 hidden sm:block">
                        <li className="rounded-lg shadow-lg dark:shadow-gray-800 p-6">
                            <div className="flex items-center pb-6 border-b border-gray-100 dark:border-gray-800">
                                <img src={classic06}
                                     className="size-16 rounded-full shadow dark:shadow-gray-800" alt=""/>

                                <div className="ps-4">
                                    <span className="text-lg hover:text-indigo-600 duration-500 ease-in-out">Janisha Doll</span>
                                </div>
                            </div>

                            <div className="mt-6">
                                <p className="text-slate-400">Thanks to IG Champ, my video views on Instagram shot
                                    through the roof! This service gave me the social proof I needed to establish myself
                                    as a credible brand. I’ve noticed a significant increase in new followers and
                                    interactions since using it.</p>
                                <ul className="list-none mb-0 text-amber-400 mt-2">
                                    <li className="inline"><i className="mdi mdi-star"></i></li>
                                    <li className="inline"><i className="mdi mdi-star"></i></li>
                                    <li className="inline"><i className="mdi mdi-star"></i></li>
                                    <li className="inline"><i className="mdi mdi-star"></i></li>
                                    <li className="inline"><i className="mdi mdi-star"></i></li>
                                </ul>
                            </div>
                        </li>

                        <li className="rounded-lg shadow-lg dark:shadow-gray-800 p-6">
                            <div className="flex items-center pb-6 border-b border-gray-100 dark:border-gray-800">
                                <img src={classic07}
                                     className="size-16 rounded-full shadow dark:shadow-gray-800" alt=""/>

                                <div className="ps-4">
                                    <span className="text-lg hover:text-indigo-600 duration-500 ease-in-out">Jill
                                        Webb</span>
                                </div>
                            </div>

                            <div className="mt-6">
                                <p className="text-slate-400">IG Champ’s TikTok Followers service was exactly what I
                                    needed to gain traction on my new TikTok account. I gained major amount of followers
                                    in just a few days, and the best part is, they engage with my content. It’s the real
                                    deal!</p>
                                <ul className="list-none mb-0 text-amber-400 mt-2">
                                    <li className="inline"><i className="mdi mdi-star"></i></li>
                                    <li className="inline"><i className="mdi mdi-star"></i></li>
                                    <li className="inline"><i className="mdi mdi-star"></i></li>
                                    <li className="inline"><i className="mdi mdi-star"></i></li>
                                    <li className="inline"><i className="mdi mdi-star"></i></li>
                                </ul>
                            </div>
                        </li>
                    </ul>

                    <ul className="space-y-8 hidden lg:block">
                        <li className="rounded-lg shadow-lg dark:shadow-gray-800 p-6">
                            <div className="flex items-center pb-6 border-b border-gray-100 dark:border-gray-800">
                                <img src={classic08}
                                     className="size-16 rounded-full shadow dark:shadow-gray-800" alt=""/>

                                <div className="ps-4">
                                    <span className="text-lg hover:text-indigo-600 duration-500 ease-in-out">Barbara
                                        McIntosh</span>
                                </div>
                            </div>

                            <div className="mt-6">
                                <p className="text-slate-400">After using IG Champ’s service to buy TikTok Likes, I saw
                                    a huge difference in my video’s popularity. The likes helped boost my content,
                                    getting it in front of more viewers and even landing it on the For You page. I
                                    couldn’t be happier with the results!</p>
                                <ul className="list-none mb-0 text-amber-400 mt-2">
                                    <li className="inline"><i className="mdi mdi-star"></i></li>
                                    <li className="inline"><i className="mdi mdi-star"></i></li>
                                    <li className="inline"><i className="mdi mdi-star"></i></li>
                                    <li className="inline"><i className="mdi mdi-star"></i></li>
                                    <li className="inline"><i className="mdi mdi-star"></i></li>
                                </ul>
                            </div>
                        </li>

                        <li className="rounded-lg shadow-lg dark:shadow-gray-800 p-6">
                            <div className="flex items-center pb-6 border-b border-gray-100 dark:border-gray-800">
                                <img src={classic09}
                                     className="size-16 rounded-full shadow dark:shadow-gray-800" alt=""/>

                                <div className="ps-4">
                                    <span
                                        className="text-lg hover:text-indigo-600 duration-500 ease-in-out">Carl Oliver</span>
                                </div>
                            </div>

                            <div className="mt-6">
                                <p className="text-slate-400">My TikTok videos were struggling to get noticed until I
                                    found IG Champ’s TikTok Views service. Now my views have multiplied, and I’m gaining
                                    followers every day. This service has truly given my profile the boost it
                                    needed!</p>
                                <ul className="list-none mb-0 text-amber-400 mt-2">
                                    <li className="inline"><i className="mdi mdi-star"></i></li>
                                    <li className="inline"><i className="mdi mdi-star"></i></li>
                                    <li className="inline"><i className="mdi mdi-star"></i></li>
                                    <li className="inline"><i className="mdi mdi-star"></i></li>
                                    <li className="inline"><i className="mdi mdi-star"></i></li>
                                </ul>
                            </div>
                        </li>
                    </ul>
                </div>

            </section>


            <section className="relative md:pt-16 pb-40 py-16 bg-gray-50 dark:bg-slate-800">


                <GetInTuch title={false}/>
            </section>
            <div className="relative">
                <div
                    className="shape absolute sm:-bottom-px -bottom-[2px] start-0 end-0 overflow-hidden z-1 text-dark-footer">
                    <svg className="w-full h-auto scale-[2.0] origin-top" viewBox="0 0 2880 250" fill="none"
                         xmlns="http://www.w3.org/2000/svg">
                        <path d="M720 125L2160 0H2880V250H0V125H720Z" fill="currentColor"></path>
                    </svg>
                </div>
            </div>

            <Footer/>

        </div>
    )
}
